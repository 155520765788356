import { Grid } from "@material-ui/core";
import { Popup } from "react-mapbox-gl";
import React from "react";
import { RowDetails } from "@dpdgroupuk/central-collections-common";

export const CollectionsPopUp = ({
  coordinates,
  properties,
  countOffset = 1
}) => {
  return (
    <Popup coordinates={coordinates} style={{ width: "400px" }}>
      <Grid container>
        <Grid item xs={12}>
          <RowDetails
            title={"Number of collections"}
            value={properties.count / countOffset}
          />
        </Grid>
      </Grid>
    </Popup>
  );
};
