import {
  MAIN_LAYER_TYPE,
  SET_MAIN_LAYER_TYPE,
  SET_MAIN_LAYER_DATA,
  GENERAL_VIEW,
  SET_VIEW,
  DEPOT_INFO,
  ROUTE_VIEW,
  SET_BOUNDING_BOX
} from "../../actions/constants";

const initialState = {
  boundingBox: undefined,
  mainLayerData: undefined,
  mainLayerType: MAIN_LAYER_TYPE.DEPOT,
  currentState: {
    view: GENERAL_VIEW
  }
};

export default function mapReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MAIN_LAYER_DATA:
      return { ...state, mainLayerData: action.payload };
    case SET_MAIN_LAYER_TYPE:
      return { ...state, mainLayerType: action.payload };
    case SET_VIEW:
      return {
        ...state,
        currentState: action.value
      };
    case DEPOT_INFO:
      return {
        ...state,
        depotInfo: action.value
      };
    case ROUTE_VIEW: {
      return {
        ...state,
        routesInfo: action.value
      };
    }

    case SET_BOUNDING_BOX:
      return {
        ...state,
        boundingBox: action.payload
      };
    default:
      return state;
  }
}
