import {
  Divider,
  Drawer,
  Grid,
  Input,
  Typography,
  makeStyles
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DateTime } from "luxon";
import { RowDetails } from "@dpdgroupuk/central-collections-common";
import dpdLogo from "../../assets/icons/DPDG_logo_320.png";
import { findMatrixLine } from "./utils";
import { useEffect } from "react";

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  dpdLogo: {
    width: "170px"
  },
  drawerContainer: {
    padding: "10px"
  }
}));

export const SidePanel = () => {
  const classes = useStyles();
  const { sidePanel, mapping, matrixTemplate } = useSelector(
    state => state.matrixTemplateReducer
  );
  const [postcode, setPostcode] = useState();
  const [line, setLine] = useState();
  const dispatch = useDispatch();

  const feature = sidePanel.selectedFeature;
  useEffect(() => {
    getTemplateLine();
  }, [feature]);

  const getTemplateLine = () => {
    if (!sidePanel.selectedFeature) {
      return;
    }

    const postcode = mapping.featureToPostcode[sidePanel.selectedFeature.id];
    const line = findMatrixLine(
      sidePanel.selectedFeature.properties,
      matrixTemplate
    );
    setPostcode(postcode);
    setLine(line);
  };

  return (
    <Drawer
      anchor={"right"}
      open={sidePanel.open}
      className={classes.drawer}
      variant="persistent"
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.drawerHeader}>
        <img src={dpdLogo} className={classes.dpdLogo} alt={"img"} />
      </div>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h2>{postcode}</h2>
        </Grid>
        {line && (
          <>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                <Typography component={"div"} variant={"body1"}>
                  Delivery Route
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Input
                  name={"delivery_route"}
                  value={line.delivery_route?.join(", ")}
                  onChange={e =>
                    setLine({ ...line, [e.target.name]: [e.target.value] })
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                <Typography component={"div"} variant={"body1"}>
                  Smalls Route
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Input
                  name={"smalls_route"}
                  value={line.smalls_route?.join(", ")}
                  onChange={e =>
                    setLine({ ...line, [e.target.name]: [e.target.value] })
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                <Typography component={"div"} variant={"body1"}>
                  Bulk Route
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Input
                  name={"bulk_route"}
                  value={line.bulk_route && line.bulk_route[0]}
                  onChange={e =>
                    setLine({ ...line, [e.target.name]: [e.target.value] })
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                <Typography component={"div"} variant={"body1"}>
                  Collection Routes
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Input
                  name={"collection_route"}
                  value={line.collection_route?.join(", ")}
                  onChange={e =>
                    setLine({ ...line, [e.target.name]: [e.target.value] })
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                <Typography component={"div"} variant={"body1"}>
                  Same Day Cut Off
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Input
                  name={"same_day_cut_off"}
                  value={DateTime.fromJSDate(
                    new Date(line.same_day_cut_off)
                  ).toFormat("HH:mm")}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Drawer>
  );
};
