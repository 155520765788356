import { TextLayer } from "deck.gl";

export const RenderLayers = props => {
  const { showLabels, ...others } = props;

  const labelsLayer = new TextLayer({
    id: "postcode-labels",
    pickable: true,
    getPosition: d => d.geometry.coordinates,
    getText: d => d.properties.name,
    getSize: 14,
    getAngle: 0,
    getTextAnchor: "middle",
    getAlignmentBaseline: "center",
    autoHighlight: true,
    highlightColor: [41, 241, 195, 120],
    ...others
  });
  return showLabels ? [labelsLayer] : [];
};
