import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mapLayer: { display: true },
  h3HexagonLayer: { display: true },
  arcsLayer: { display: false },
  postcodeLabelsLayer: { display: false }
};

const deckglLayersReducer = createSlice({
  name: "deckglLayers",
  initialState,
  reducers: {
    toggleMapLayer: state => {
      state.mapLayer.display = !state.mapLayer.display;
    },
    toggleH3HexagonLayer: state => {
      state.h3HexagonLayer.display = !state.h3HexagonLayer.display;
    },
    toggleArcsLayer: state => {
      state.arcsLayer.display = !state.arcsLayer.display;
    },
    togglePostcodeLabelsLayer: state => {
      state.postcodeLabelsLayer.display = !state.postcodeLabelsLayer.display;
    }
  }
});

export const {
  toggleMapLayer,
  toggleH3HexagonLayer,
  toggleArcsLayer,
  togglePostcodeLabelsLayer
} = deckglLayersReducer.actions;
export default deckglLayersReducer.reducer;
