import { List, ListItem, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import React from "react";
import { Select } from "@dpdgroupuk/central-collections-common";
import { setAllocation } from "../../store/reducers/matrixTemplateReducer";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

export const RouteAllocation = () => {
  const dispatch = useDispatch();
  const { allocation } = useSelector(state => state.matrixTemplateReducer);

  const selectRoute = route => {
    if (allocation.selectedRoute === route) {
      dispatch(setAllocation({ selectedRoute: null }));
    } else {
      dispatch(setAllocation({ selectedRoute: route }));
    }
  };

  return (
    <>
      <Select
        name={"similar_type"}
        defaultValue={allocation.type}
        onChange={e => dispatch(setAllocation({ type: e.target.value }))}
        options={[
          "delivery_route",
          "smalls_route",
          "bulk_route",
          "collection_route"
        ]}
      />
      <List>
        {allocation.routes.map(route => {
          return (
            <RouteItem
              selected={route === allocation.selectedRoute}
              key={route}
              route={route}
              onClick={selectRoute}
            />
          );
        })}
      </List>
    </>
  );
};

const RouteItem = ({ route, onClick, selected }) => {
  const styles = useStyles();
  return (
    <ListItem
      className={selected ? styles.selected : ""}
      onClick={() => onClick(route)}
    >
      {route}
    </ListItem>
  );
};
