import {GeoJSONLayer, Layer} from "react-mapbox-gl";
import React, { useState} from "react";

const h3 = require("h3-js");

const h3Index = h3.geoToH3(52.476282, -1.904023, 5);
const hexBoundary = h3.h3ToGeoBoundary(h3Index);
const hexagons = h3.polyfill(hexBoundary, 7);
const coordinates = h3.h3SetToMultiPolygon(hexagons, true);

const h3Index2 = h3.geoToH3(52.426282, -1.914023, 7);
const hexBoundary2 = h3.h3ToGeoBoundary(h3Index2, true);

console.log("H333", h3Index, JSON.stringify(hexagons));
console.log("H333", h3Index, JSON.stringify(hexBoundary));
console.log("H3332", h3Index, JSON.stringify(coordinates[0][0]));

let geojsonObject = {
  type: "FeatureCollection",
    features: [
      {
        "type": "Feature",
        "properties": {
          "category": "cat",
        },
        "geometry": {
          "type": "Polygon",
          "coordinates": [coordinates[0][0]]
        }
      }
      ,
      {
        "type": "Feature",
        "properties": {
          "category": "cat",
        },
        "geometry": {
          "type": "Polygon",
          "coordinates": [hexBoundary2]
        }
      }
  ]
};

export const H3Matrix = () => {
  return (
    <>
      <GeoJSONLayer
        key={"123"}
        data={geojsonObject}
        fillPaint={{
          'fill-color': "#ff0000",
          'fill-outline-color': "#000",
          'fill-opacity': 0.3
        }}
      />

    </>
  );
};
