import React, { useEffect, useState } from "react";
import ReactMapboxGl, { ZoomControl } from "react-mapbox-gl";
import { setMapCenter, zoomChanged } from "../store/reducers/zoomReducer";
import { useDispatch, useSelector } from "react-redux";

import { Boundaries } from "./Boundaries/Boundaries";
import { Collections } from "./Collections/Collections";
import { CollectionsHeatmap } from "./CollectionsHeatmap/CollectionsHeatmap";
import { DrawControls } from "./DrawControls";
import { H3Matrix } from "./MatrixTemplate/H3Matrix";
// import { Boundaries } from "./Boundaries";
import { MainLayer } from "./MainLayer";
import { Point } from "mapbox-gl";
import { PostcodeLabels } from "./PostcodeLabels";
import { PostcodeLevelMatrix } from "./MatrixTemplate/PostcodeLevelMatrix";
import { Sources } from "./Sources";
import depotPin from "../assets/icons/depot_pin.png";
import { resetSources } from "../store/reducers/sourcesReducer";
import waypoint from "../assets/icons/waypoint.png";

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  attributionControl: false
});

function MapComponent() {
  const [map, setMap] = useState();
  // const [mapCenter, setMapCenter] = useState();
  const dispatch = useDispatch();
  const state = useSelector(state => state.mapReducer.depotInfo);
  const boundingBox = useSelector(state => state.mapReducer.boundingBox);
  const layersReducer = useSelector(state => state.layersReducer);
  const { zoom, center } = useSelector(state => state.zoomReducer);
  const [start, setStart] = useState();
  const [currentPosition, setCurrentPosition] = useState();
  const [box, setBox] = useState();
  const [end, setEnd] = useState();
  const [canvas, setCanvas] = useState();

  useEffect(() => {
    dispatch(resetSources());
  }, []);

  useEffect(() => {
    if (map) {
      map.loadImage(depotPin, (error, image) => {
        if (error) {
          throw error;
        }
        map.addImage("depot", image);
      });
      map.loadImage(waypoint, (error, image) => {
        if (error) {
          throw error;
        }
        map.addImage("stop", image, { sdf: true });
      });
      setCanvas(map.getCanvasContainer());
      map.boxZoom.disable();
    }
  }, [map]);

  useEffect(() => {
    if (!canvas) {
      return;
    }
    canvas.addEventListener("mousedown", mouseDown, true);
  }, [canvas]);

  useEffect(() => {
    dispatch(setMapCenter(state));
  }, [state]);

  useEffect(() => {
    if (map && boundingBox) {
      map.fitBounds(boundingBox);
    }
  }, [map, boundingBox]);

  function mouseDown(e) {
    if (!(e.shiftKey && e.button === 0)) return;

    map.dragPan.disable();

    canvas.addEventListener("mousemove", onMouseMove);
    canvas.addEventListener("mouseup", onMouseUp);
    setStart(mousePos(e));
  }

  useEffect(() => {
    if (!box && canvas) {
      let myBox = document.createElement("div");
      myBox.classList.add("boxdraw");
      canvas.appendChild(myBox);
      setBox(myBox);
    }
    if (start && box && currentPosition) {
      var minX = Math.min(start.x, currentPosition.x),
        maxX = Math.max(start.x, currentPosition.x),
        minY = Math.min(start.y, currentPosition.y),
        maxY = Math.max(start.y, currentPosition.y);

      // Adjust width and xy position of the box element ongoing
      var pos = "translate(" + minX + "px," + minY + "px)";
      box.style.transform = pos;
      box.style.WebkitTransform = pos;
      box.style.width = maxX - minX + "px";
      box.style.height = maxY - minY + "px";
    }
  }, [start, box, currentPosition]);

  function onMouseMove(e) {
    // Capture the ongoing xy coordinates
    const current = mousePos(e);
    setCurrentPosition(current);

    // Append the box element if it doesnt exist
  }

  function onMouseUp(e) {
    setEnd(mousePos(e));
  }

  useEffect(() => {
    if (!end) {
      return;
    }
    const bbox = [start, end];
    // Remove these events now that finish has been called.
    canvas.removeEventListener("mousemove", onMouseMove);
    canvas.removeEventListener("mouseup", onMouseUp);

    if (box) {
      box.parentNode.removeChild(box);
      setBox(null);
    }

    // If bbox exists. use this value as the argument for `queryRenderedFeatures`
    if (bbox && bbox.length === 2 && bbox[0] && bbox[1]) {
      const features = map.queryRenderedFeatures(bbox, {
        layers: ["template-layer"]
      });
      const selected = features.map(f => f.properties.name);

      window.alert(selected);
    }

    map.dragPan.enable();
    setEnd(null);
    setStart(null);
    setBox(null);
    setCurrentPosition(null);
  }, [end]);

  function mousePos(e) {
    var rect = canvas.getBoundingClientRect();
    return new Point(
      e.clientX - rect.left - canvas.clientLeft,
      e.clientY - rect.top - canvas.clientTop
    );
  }

  return (
    <>
      <Map
        // eslint-disable-next-line react/style-prop-object
        style={"mapbox://styles/mapbox/light-v10?optimize=true"}
        containerStyle={{
          width: "100%",
          height: "100%"
        }}
        attributionControl={false}
        onStyleLoad={setMap}
        center={center}
        onZoom={() => {
          map && dispatch(zoomChanged(map.getZoom()));
        }}
        zoom={zoom ? [zoom] : [5]}
      >
        {map && <DrawControls map={map} />}
        <ZoomControl position={"bottom-right"} />
        <Sources map={map} />
        {layersReducer.boundariesLayer.display && <Boundaries map={map} />}
        {layersReducer.mainLayer.display && <MainLayer />}
        {layersReducer.labelsLayer.display && <PostcodeLabels map={map} />}

        {layersReducer.matrixTemplateLayer.display && (
          <PostcodeLevelMatrix map={map} />
        )}
        {layersReducer.matrixH3Layer.display && <H3Matrix />}
        {layersReducer.collectionsLayer.display && <Collections />}
        {layersReducer.collectionsHeatmapLayer.display && (
          <CollectionsHeatmap />
        )}
      </Map>
    </>
  );
}

export default MapComponent;
