import {
  CheckboxWithLabel,
  Collapsible,
  Select
} from "@dpdgroupuk/central-collections-common";
import { Grid, Slider } from "@material-ui/core";
import React, { useEffect } from "react";
import {
  setBulkRoutes,
  setDeliveryRoutes,
  setDepotFilter,
  setHighlightSimilar,
  toggleOnHover
} from "../../store/reducers/boundariesReducer";
import { useDispatch, useSelector } from "react-redux";

import { SketchPicker } from "react-color";
import { getNewTable } from "./utils";
import { getZoomLevel } from "../MatrixTemplate/utils";
import { isArray } from "lodash";
import randomColor from "randomcolor";

export const BoundariesSideBar = () => {
  const {
    onHover,
    depotFilter,
    highlightSimilar,
    bulkRoutes,
    deliveryRoutes
  } = useSelector(state => state.boundariesReducer);
  const { postcodeAllocation } = useSelector(
    state => state.postcodeAllocationReducer
  );
  const { zoom, level } = useSelector(state => state.zoomReducer);
  const { matrixPostcodeMapping, mapping } = useSelector(
    state => state.matrixTemplateReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (bulkRoutes.enabled) {
      enableBulkRoutes();
    }
  }, [level, bulkRoutes.enabled]);

  useEffect(() => {
    if (deliveryRoutes.enabled) {
      enableDeliveryRoutes();
    }
  }, [level, deliveryRoutes.enabled]);

  const enableBulkRoutes = () => {
    const bulkToFeature = {};
    const routeColor = {};
    const bulkToLabel = {};
    if (bulkRoutes.enabled) {
      const lines = matrixPostcodeMapping[level === 3 ? level : level - 1];
      const routes = [
        ...new Set(
          Object.values(lines)
            .map(v => v.bulk_route)
            .flat()
        )
      ];
      const colors = randomColor({ count: routes.length });
      routes.forEach((r, i) => {
        routeColor[r] = colors[i];
      });
      const lookupTable = getNewTable(level)[`pos${level + 1}`].data.all;
      Object.keys(lines).forEach(key => {
        if (lines[key].bulk_route[0]) {
          const feature = Object.values(lookupTable).find(
            f => f.unit_code === key
          );
          bulkToFeature[feature?.feature_id || -1] = {
            bulkRoute: lines[key].bulk_route[0],
            bulkRouteColor: routeColor[lines[key].bulk_route[0]]
          };
          bulkToLabel[mapping.postcodeToFeature[key]] =
            lines[key].bulk_route[0];
        }
      });
    }
    dispatch(
      setBulkRoutes({ routes: bulkToFeature, routeColor, labels: bulkToLabel })
    );
  };

  const enableDeliveryRoutes = () => {
    const deliveryToFeature = {};
    const routeColor = {};
    const deliveryToLabel = {};
    if (deliveryRoutes.enabled) {
      const lines = matrixPostcodeMapping[level === 3 ? level : level - 1];
      const routes = [
        ...new Set(
          Object.values(lines)
            .map(v => v.delivery_route)
            .flat()
        )
      ];
      const colors = randomColor({ count: routes.length });
      routes.forEach((r, i) => {
        routeColor[r] = colors[i];
      });
      const lookupTable = getNewTable(level)[`pos${level + 1}`].data.all;
      Object.keys(lines).forEach(key => {
        const feature =
          lookupTable[`GBP${level + 1}${key.replace(" ", "").toUpperCase()}`];
        const waves = {};
        lines[key].delivery_route.forEach((r, i) => {
          waves[`delivery_${i}_route`] = r;
          waves[`delivery_${i}_color`] = routeColor[r];
        });
        deliveryToFeature[feature?.feature_id || -1] = waves;
        deliveryToLabel[mapping.postcodeToFeature[key]] = waves;
      });
    }
    dispatch(
      setDeliveryRoutes({
        routes: deliveryToFeature,
        routeColor,
        labels: deliveryToLabel
      })
    );
  };

  return (
    <>
      <h4>Boundaries Options</h4>
      <CheckboxWithLabel
        check={onHover}
        label={"On Hover"}
        onChange={() => dispatch(toggleOnHover())}
      />
      <CheckboxWithLabel
        check={depotFilter.enabled}
        label={"Depot Filter"}
        onChange={() =>
          dispatch(setDepotFilter({ enabled: !depotFilter.enabled }))
        }
      />
      {depotFilter.enabled && (
        <Select
          name={"depot_filter"}
          defaultValue={depotFilter.depot}
          onChange={e => dispatch(setDepotFilter({ depot: e.target.value }))}
          options={Object.keys(postcodeAllocation)}
        />
      )}
      <CheckboxWithLabel
        check={highlightSimilar.enabled}
        label={"Highlight Similar"}
        onChange={() =>
          dispatch(setHighlightSimilar({ enabled: !highlightSimilar.enabled }))
        }
      />
      {highlightSimilar.enabled && (
        <>
          <Select
            name={"similar_type"}
            defaultValue={highlightSimilar.type}
            onChange={e =>
              dispatch(setHighlightSimilar({ type: e.target.value }))
            }
            options={[
              "delivery_route",
              "smalls_route",
              "bulk_route",
              "collection_route"
            ]}
          />
          <SketchPicker
            color={highlightSimilar.colour}
            onChangeComplete={e =>
              dispatch(setHighlightSimilar({ colour: e.hex }))
            }
          />
        </>
      )}

      <CheckboxWithLabel
        check={bulkRoutes.enabled}
        label={"Show Bulk Routes"}
        onChange={() =>
          dispatch(setBulkRoutes({ enabled: !bulkRoutes.enabled }))
        }
      />
      {bulkRoutes.enabled && (
        <Collapsible
          title={"Route Colours"}
          headerTypographyVariant={"body1"}
          border={false}
          defaultCollapsed={true}
        >
          <Grid container>
            {bulkRoutes.routeColor &&
              Object.keys(bulkRoutes.routeColor).map(route => (
                <Grid item xs={12} container>
                  <Grid
                    item
                    xs={2}
                    style={{
                      borderBottom: "1px solid black",
                      backgroundColor: bulkRoutes.routeColor[route],
                      opacity: 0.2
                    }}
                  />
                  <Grid item xs={10}>
                    {route}
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Collapsible>
      )}
      <CheckboxWithLabel
        check={deliveryRoutes.enabled}
        label={"Show Delivery Routes"}
        onChange={() =>
          dispatch(setDeliveryRoutes({ enabled: !deliveryRoutes.enabled }))
        }
      />
      {deliveryRoutes.enabled && (
        <>
          <h4>Waves</h4>
          <Grid container>
            {Object.keys(deliveryRoutes.waves).map(w => (
              <>
                <Grid item xs={6}>
                  <CheckboxWithLabel
                    check={deliveryRoutes.waves[w].enabled}
                    label={`Wave ${parseInt(w, 10) + 1}`}
                    onChange={() =>
                      dispatch(
                        setDeliveryRoutes({
                          waves: {
                            ...deliveryRoutes.waves,
                            [w]: {
                              enabled: !deliveryRoutes.waves[w].enabled,
                              opacity: deliveryRoutes.waves[w].opacity
                            }
                          }
                        })
                      )
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Slider
                    value={deliveryRoutes.waves[w].opacity * 100}
                    onChange={(e, value) => {
                      dispatch(
                        setDeliveryRoutes({
                          waves: {
                            ...deliveryRoutes.waves,
                            [w]: {
                              enabled: deliveryRoutes.waves[w].enabled,
                              opacity: value / 100
                            }
                          }
                        })
                      );
                    }}
                  />
                </Grid>
              </>
            ))}
          </Grid>

          <Collapsible
            title={"Route Colours"}
            headerTypographyVariant={"body1"}
            border={false}
            defaultCollapsed={true}
          >
            <Grid container>
              {deliveryRoutes.routeColor &&
                Object.keys(deliveryRoutes.routeColor).map(route => (
                  <Grid item xs={12} container>
                    <Grid
                      item
                      xs={2}
                      style={{
                        borderBottom: "1px solid black",
                        backgroundColor: deliveryRoutes.routeColor[route],
                        opacity: 0.2
                      }}
                    />
                    <Grid item xs={10}>
                      {route}
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Collapsible>
        </>
      )}
    </>
  );
};
