import React, { useState } from "react";
import { changeTab, filterTreeData } from "../actions/sideBarActions";
import {
  toggleArcsLayer,
  toggleH3HexagonLayer,
  toggleMapLayer,
  togglePostcodeLabelsLayer
} from "../store/reducers/deckglLayersReducer";
import {
  toggleBoundariesLayer,
  toggleCollectionsHeatmapLayer,
  toggleCollectionsLayer,
  toggleLabelLayer,
  toggleMainLayer,
  toggleMatrixH3Layer,
  toggleMatrixTemplateLayer
} from "../store/reducers/layersReducer";
import { useDispatch, useSelector } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import { BoundariesSideBar } from "./Boundaries/BoundariesSideBar";
import Box from "@material-ui/core/Box";
import { CheckboxWithLabel } from "@dpdgroupuk/central-collections-common";
import { CollectionsSideBar } from "./Collections/CollectionsSideBar";
import { DeliveryRoutes } from "./Boundaries/DeliveryRoutes";
import DepotView from "./Depot/DepotView";
import { InfoView } from "./InfoView";
import PropTypes from "prop-types";
import { SidePanel } from "./MultiMaps/SidePanel";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { TemplateSideBar } from "./MatrixTemplate/TemplateSideBar";
import { TemplateSideBarDeckGl } from "./NewMapComponent/MatrixTemplate/TemplateSideBarDeckGl";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  searchInput: {
    margin: "10px"
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const TabsComponent = () => {
  const classes = useStyles();

  const [typingTimeout, setTypingTimeout] = useState();

  const dispatch = useDispatch();

  const selectedTab = useSelector(state => state.sideBarReducer.selectedTab);
  const layersReducer = useSelector(state => state.layersReducer);
  const deckglLayersReducer = useSelector(state => state.deckglLayersReducer);
  const infoView = useSelector(state => state.sideBarReducer.infoData);

  const handleChange = (event, newValue) => {
    dispatch(changeTab(newValue));
  };

  const onChange = event => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const value = event.target.value;
    setTypingTimeout(
      setTimeout(() => {
        dispatch(filterTreeData(value));
      }, 500)
    );
  };

  const onClickHandlers = {
    mainLayer: () => dispatch(toggleMainLayer()),
    labelsLayer: () => dispatch(toggleLabelLayer()),
    matrixTemplateLayer: () => dispatch(toggleMatrixTemplateLayer()),
    matrixH3Layer: () => dispatch(toggleMatrixH3Layer()),
    collectionsLayer: () => dispatch(toggleCollectionsLayer()),
    boundariesLayer: () => dispatch(toggleBoundariesLayer()),
    collectionsHeatmapLayer: () => dispatch(toggleCollectionsHeatmapLayer()),

    mapLayer: () => dispatch(toggleMapLayer()),
    h3HexagonLayer: () => dispatch(toggleH3HexagonLayer()),
    arcsLayer: () => dispatch(toggleArcsLayer()),
    postcodeLabelsLayer: () => dispatch(togglePostcodeLabelsLayer())
  };

  return (
    <>
      <AppBar position="static">
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={selectedTab}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="react-mapbox-gl" {...a11yProps(0)} />
          <Tab label="deck.gl" {...a11yProps(1)} />
          <Tab label="waves multi map" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={selectedTab} index={0}>
        {Object.keys(layersReducer).map((key, index) => (
          <div key={index}>
            <CheckboxWithLabel
              check={layersReducer[key].display}
              label={key}
              onChange={onClickHandlers[key]}
              // onChange={() => {console.log(key)}}
            />
          </div>
        ))}
        {layersReducer.mainLayer.display && (
          <>
            {infoView ? (
              <InfoView />
            ) : (
              <>
                <TextField
                  className={classes.searchInput}
                  id="outlined-basic"
                  label="Filter depot/route"
                  onChange={onChange}
                />
                <DepotView />
              </>
            )}
          </>
        )}
        {(layersReducer.collectionsLayer.display ||
          layersReducer.collectionsHeatmapLayer.display) && (
          <CollectionsSideBar />
        )}
        {layersReducer.matrixTemplateLayer.display && <TemplateSideBar />}
        {layersReducer.boundariesLayer.display && <BoundariesSideBar />}
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        {Object.keys(deckglLayersReducer).map((key, index) => (
          <div key={"deck-" + index}>
            <CheckboxWithLabel
              check={deckglLayersReducer[key].display}
              label={key}
              onChange={onClickHandlers[key]}
            />
          </div>
        ))}
        {deckglLayersReducer.postcodeLabelsLayer.display && (
          <TemplateSideBarDeckGl />
        )}
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <SidePanel />
      </TabPanel>
    </>
  );
};

export default TabsComponent;
