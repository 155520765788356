import {
  POSTCODE_DISTRICT_BOUNDARIES_ID,
  POSTCODE_SECTOR_BOUNDARIES_ID,
  POSTCODE_UNIT_BOUNDARIES_ID
} from "../../store/reducers/sourcesReducer";
import React, { useState } from "react";
import ReactMapboxGl, { ZoomControl } from "react-mapbox-gl";
import { setMapCenter, zoomChanged } from "../../store/reducers/zoomReducer";
import { useDispatch, useSelector } from "react-redux";

import { BoundariesSingleMap } from "./BoundariesSingleMap";
import { Labels } from "./Labels";
import { Sources } from "../Sources";
import { makeStyles } from "@material-ui/core";
import { useEffect } from "react";

export const sourceMapping = {
  0: "postcode_area_boundaries",
  1: POSTCODE_DISTRICT_BOUNDARIES_ID,
  2: POSTCODE_SECTOR_BOUNDARIES_ID,
  3: POSTCODE_UNIT_BOUNDARIES_ID
};

const layerNames = [
  "postcode-district-boundaries",
  "postcode-sector-boundaries",
  "postcode-unit-boundaries"
];

const useStyles = makeStyles(theme => ({
  name: {
    position: "absolute",
    left: 10,
    top: 10,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: 5,
    zIndex: 1000,
    maxWidth: "60px"
  },
  container: {
    position: "relative",
    width: "100%",
    height: "100%"
  }
}));

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  attributionControl: false
});

export const SingleMap = ({
  wave,
  onMouseMove,
  selected,
  onDrag,
  waveNumber,
  highlightFeature
}) => {
  const styles = useStyles();
  const { zoom, center, level } = useSelector(state => state.zoomReducer);
  const { deliveryRoutes } = useSelector(state => state.multiMapReducer);
  const [map, setMap] = useState();
  const [featureId, setFeatureId] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (highlightFeature !== featureId) {
      if (map) {
        map.setFeatureState(
          {
            source: sourceMapping[level],
            sourceLayer: `boundaries_postal_${level + 1}`,
            id: featureId
          },
          {
            hover: false
          }
        );
        map.setFeatureState(
          {
            source: sourceMapping[level],
            sourceLayer: `boundaries_postal_${level + 1}`,
            id: highlightFeature
          },
          {
            hover: true
          }
        );
      }
      setFeatureId(highlightFeature);
    }
  }, [highlightFeature, map]);

  useEffect(() => {
    if (!map || !deliveryRoutes.routes) {
      return;
    }
    Object.keys(deliveryRoutes.routes).forEach(id => {
      map.setFeatureState(
        {
          source: sourceMapping[level],
          sourceLayer: `boundaries_postal_${level + 1}`,
          id
        },
        deliveryRoutes.routes[id]
      );
    });
  }, [deliveryRoutes.routes, map]);

  return (
    <div className={styles.container}>
      <div className={styles.name}>{wave.name}</div>
      <Map
        // eslint-disable-next-line react/style-prop-object
        style={"mapbox://styles/mapbox/light-v10"}
        containerStyle={{
          width: "100%",
          height: "100%"
        }}
        attributionControl={false}
        onStyleLoad={setMap}
        center={center}
        onZoomEnd={() => {
          if (selected && map) {
            dispatch(zoomChanged(map.getZoom()));
            dispatch(setMapCenter(map.getCenter()));
          }
        }}
        zoom={zoom ? [zoom] : [5]}
        onMouseMove={(e, i) => {
          if (map) {
            const features = map.queryRenderedFeatures(i.point, {
              layers: layerNames
            });
            onMouseMove(features);
          }
        }}
        onDragEnd={() => selected && onDrag(map.getCenter())}
        movingMethod={"flyTo"}
      >
        <Sources map={map} />
        <BoundariesSingleMap wave={waveNumber} />
        <Labels waveIndex={waveNumber} map={map} />
      </Map>
    </div>
  );
};
