export const SET_MAIN_LAYER_DATA = "SET_MAIN_LAYER_DATA";
export const SET_MAIN_LAYER_TYPE = "SET_MAIN_LAYER_TYPE";
export const SET_BOUNDING_BOX = "SET_BOUNDING_BOX";

export const MAIN_LAYER_TYPE = {
  DEPOT: "depot",
  ROUTE: "stop",
  STOP: "stop"
};

export const SET_VIEW = "SET_VIEW";
export const DEPOT_INFO = "DEPOT_INFO";
export const GENERAL_VIEW = "General View";
export const DEPOT_VIEW = "Depot View";
export const ROUTE_VIEW = "Route View";

export const DIRECTIONS_API =
  "https://api.mapbox.com/directions/v5/mapbox/driving/";

export const SET_TREE_DATA = "SET_TREE_DATA";
export const SET_INFO_DATA = "SET_INFO_DATA";
export const TOGGLE_SIDE_BAR = "TOGGLE_SIDE_BAR";
export const CHANGE_TAB = "CHANGE_TAB";
