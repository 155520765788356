import {
  POSTCODE_AREA_BOUNDARIES,
  POSTCODE_DISTRICT_BOUNDARIES,
  POSTCODE_DISTRICT_LABELS,
  POSTCODE_SECTOR_BOUNDARIES,
  POSTCODE_SECTOR_LABELS,
  POSTCODE_UNIT_BOUNDARIES,
  POSTCODE_UNIT_LABELS
} from "../constants/constants";
import {
  POSTCODE_DISTRICT_BOUNDARIES_ID,
  POSTCODE_DISTRICT_LABELS_ID,
  POSTCODE_SECTOR_BOUNDARIES_ID,
  POSTCODE_SECTOR_LABELS_ID,
  POSTCODE_UNIT_BOUNDARIES_ID,
  POSTCODE_UNIT_LABELS_ID,
  setSourceLoaded
} from "../store/reducers/sourcesReducer";
import { useDispatch, useSelector } from "react-redux";

import React from "react";
import { Source } from "react-mapbox-gl";
import districtTable from "./Boundaries/lookupTables/boundaries-pos2-v3.json";
import sectorTable from "./Boundaries/lookupTables/boundaries-pos3-v3.json";
import unitTable from "./Boundaries/lookupTables/boundaries-pos4-v3.json";

export const Sources = ({ map }) => {
  const { postcodeMapping } = useSelector(
    state => state.postcodeAllocationReducer
  );
  const dispatch = useDispatch();

  return postcodeMapping && map ? (
    <>
      <Source
        id={POSTCODE_DISTRICT_LABELS_ID}
        tileJsonSource={POSTCODE_DISTRICT_LABELS}
        onSourceLoaded={source => dispatch(setSourceLoaded(source.id))}
      />
      <Source
        id={POSTCODE_SECTOR_LABELS_ID}
        tileJsonSource={POSTCODE_SECTOR_LABELS}
        onSourceLoaded={source => dispatch(setSourceLoaded(source.id))}
      />
      <Source
        id={POSTCODE_UNIT_LABELS_ID}
        tileJsonSource={POSTCODE_UNIT_LABELS}
        onSourceLoaded={source => dispatch(setSourceLoaded(source.id))}
      />
      <Source
        id={POSTCODE_DISTRICT_BOUNDARIES_ID}
        tileJsonSource={POSTCODE_DISTRICT_BOUNDARIES}
        onSourceLoaded={source => {
          Object.values(districtTable.pos2.data.all).forEach(function (row) {
            map.setFeatureState(
              {
                source: POSTCODE_DISTRICT_BOUNDARIES_ID,
                sourceLayer: "boundaries_postal_2",
                id: row.feature_id
              },
              {
                unit_code: row.unit_code,
                depotCode: postcodeMapping[row.unit_code]?.depotCode
              }
            );
          });
          dispatch(setSourceLoaded(source.id));
        }}
      />
      <Source
        id={POSTCODE_SECTOR_BOUNDARIES_ID}
        tileJsonSource={POSTCODE_SECTOR_BOUNDARIES}
        onSourceLoaded={source => {
          Object.values(sectorTable.pos3.data.all).forEach(function (row) {
            map.setFeatureState(
              {
                source: POSTCODE_SECTOR_BOUNDARIES_ID,
                sourceLayer: "boundaries_postal_3",
                id: row.feature_id
              },
              {
                unit_code: row.unit_code,
                depotCode: postcodeMapping[row.unit_code]?.depotCode
              }
            );
          });
          dispatch(setSourceLoaded(source.id));
        }}
      />
      <Source
        id={POSTCODE_UNIT_BOUNDARIES_ID}
        tileJsonSource={POSTCODE_UNIT_BOUNDARIES}
        onSourceAdded={source => {
          Object.values(unitTable.pos4.data.all).forEach(function (row, index) {
            map.setFeatureState(
              {
                source: POSTCODE_UNIT_BOUNDARIES_ID,
                sourceLayer: "boundaries_postal_4",
                id: row.feature_id
              },
              {
                unit_code: row.unit_code,
                depotCode: postcodeMapping[row.unit_code]?.depotCode
              }
            );
          });
          dispatch(setSourceLoaded(source.id));
        }}
      />

      <Source
        id={"h3json"}
        type={"geojson"}
        data={
          "https://raw.githubusercontent.com/glynnbird/usstatesgeojson/master/alaska.geojson"
        }
      />
    </>
  ) : null;
};
