import "react-datepicker/dist/react-datepicker.css";

import React, { useState } from "react";
import {
  setCollections,
  setHexLevel,
  setScale
} from "../../store/reducers/collectionsReducer";
import { useDispatch, useSelector } from "react-redux";

import { DateRangePicker } from "@dpdgroupuk/central-collections-common";
import { DateTime } from "luxon";
import { Input } from "@material-ui/core";
import { useEffect } from "react";

export const CollectionsSideBar = () => {
  const { hexLevel, scale } = useSelector(state => state.collectionsReducer);
  const dispatch = useDispatch();
  const [dates, setDates] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const startDate = DateTime.fromJSDate(dates[0]).toFormat("yyyy-LL-MM");
      const endDate = DateTime.fromJSDate(dates[1]).toFormat("yyyy-LL-MM");
      fetch(`${process.env.REACT_APP_MATRIX_API}/depot/0030/test/collections`, {
        body: JSON.stringify({ startDate, endDate }),
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8"
        }
      })
        .then(d => d.json())
        .then(result => {
          console.log(result);
          dispatch(setCollections(result.collections));
        })
        .catch(err => {
          console.log(err);
        });
    };
    if (!dates) {
      return;
    }
    console.log("fetching");
    fetchData();
  }, [dates]);

  return (
    <>
      <div>
        Hex Level
        <Input
          type={"number"}
          value={hexLevel}
          onChange={e => {
            dispatch(setHexLevel(e.target.value));
          }}
          inputProps={{
            min: 0,
            max: 15
          }}
        />
      </div>
      <div>
        Scale
        <Input
          type={"number"}
          value={scale}
          onChange={e => {
            dispatch(setScale(e.target.value));
          }}
          inputProps={{
            min: 0,
            max: 100
          }}
        />
      </div>
      <DateRangePicker
        labelTextStart={"From"}
        labelTextEnd={"To"}
        valueStart={"23/11/2020"}
        valueEnd={"30/11/2020"}
        onChange={setDates}
      />
    </>
  );
};
