import { depotData } from "../constants/constants";
import * as constants from "./constants";
import GeoJSON from "geojson";
import axios from "axios";
import {
  getBoundingBoxFromGEOJsonFeatures,
  getBoundingBoxFromCoordinatesArray
} from "./utils";

const setMainLayerData = geoJSONData => {
  return {
    payload: geoJSONData,
    type: constants.SET_MAIN_LAYER_DATA
  };
};

const setMainLayerType = type => {
  return {
    payload: type,
    type: constants.SET_MAIN_LAYER_TYPE
  };
};

const actionSetCurrentState = value => {
  return {
    type: constants.SET_VIEW,
    value
  };
};

export const actionSetDepotInfo = value => {
  return {
    type: constants.DEPOT_INFO,
    value
  };
};

export const actionSetRoutes = value => {
  return {
    type: constants.ROUTE_VIEW,
    value
  };
};

export const setCurrentState = state => {
  return dispatch => {
    dispatch(actionSetCurrentState(state));
  };
};

export const setBoundingBox = payload => {
  return {
    payload,
    type: constants.SET_BOUNDING_BOX
  };
};

export const getDepots = () => {
  return async dispatch => {
    try {
      // fetch data from the back end
      // in the time being just get the list of depots from the constant
      const depots = GeoJSON.parse(depotData.children, {
        Point: ["latitude", "longitude"],
        extra: {
          icon: "depot",
          "text-anchor": "top",
          "text-offset": [0, 0],
          "text-color": "#000000",
          "icon-size": 1
        }
      });
      dispatch(setMainLayerData(depots));
      dispatch(setMainLayerType(constants.MAIN_LAYER_TYPE.DEPOT));
      const boundingBox = getBoundingBoxFromGEOJsonFeatures(depots.features);
      dispatch(setBoundingBox(boundingBox));
    } catch (e) {
      throw e;
    }
  };
};

export const getDepotRoutes = depot => {
  return async dispatch => {
    try {
      const routes = GeoJSON.parse(depot.children, {
        Point: ["latitude", "longitude"],
        extra: {
          icon: "stop",
          "text-anchor": "top",
          "text-offset": [0, 0],
          "text-color": "#000000",
          "icon-size": 0.5
        }
      });
      routes.features.unshift(
        GeoJSON.parse(depot, {
          Point: ["latitude", "longitude"],
          extra: {
            icon: "depot",
            "text-anchor": "top",
            "text-offset": [0, 0],
            "text-color": "#000000",
            "icon-size": 0.75
          }
        })
      );

      dispatch(setMainLayerData(routes));
      dispatch(setMainLayerType(constants.MAIN_LAYER_TYPE.ROUTE));
      const boundingBox = getBoundingBoxFromGEOJsonFeatures(
        routes.features,
        0.001
      );
      dispatch(setBoundingBox(boundingBox));
    } catch (e) {
      throw e;
    }
  };
};

export const getRouteDetails = route => {
  return async dispatch => {
    try {
      // get the points for the route
      const routePoints = route.children.map(
        item => `${item.longitude},${item.latitude}`
      );

      const { data } = await axios.get(
        `${constants.DIRECTIONS_API}${routePoints.join(";")}`,
        {
          params: {
            geometries: "geojson",
            continue_straight: false,
            overview: "full",
            access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
          }
        }
      );

      const routes = data.routes[0];
      const coordinates = routes.geometry.coordinates;
      const waypoints = GeoJSON.parse(data.waypoints, {
        Point: "location",
        extra: {
          icon: "stop",
          "text-anchor": "top",
          "text-color": "#000000",
          "icon-size": 0.5
        }
      });
      waypoints.features.forEach(
        (feature, index) =>
          (feature.properties = {
            ...feature.properties,
            name: route.children[index].name,
            status: route.children[index].status
          })
      );
      const geojson = {
        type: "FeatureCollection",
        features: [
          ...waypoints.features,
          ...data.routes.map(route => ({
            type: "Feature",
            properties: route,
            geometry: route.geometry
          }))
        ]
      };
      dispatch(setMainLayerData(geojson));
      dispatch(setMainLayerType(constants.MAIN_LAYER_TYPE.STOP));
      const boundingBox = getBoundingBoxFromCoordinatesArray(coordinates);
      dispatch(setBoundingBox(boundingBox));
    } catch (e) {
      throw e;
    }
  };
};
