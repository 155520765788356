import { createSlice } from "@reduxjs/toolkit";

export const POSTCODE_DISTRICT_LABELS_ID = "postcodeDistrictLabels";
export const POSTCODE_SECTOR_LABELS_ID = "postcodeSectorLables";
export const POSTCODE_UNIT_LABELS_ID = "postcodeUnitLabels";
export const POSTCODE_DISTRICT_BOUNDARIES_ID = "postcodeDistrictBoundaries";
export const POSTCODE_SECTOR_BOUNDARIES_ID = "postcodeSectorBoundaries";
export const POSTCODE_UNIT_BOUNDARIES_ID = "postcodeUnitBoundaries";
export const POSTCODE_LEVEL_MATRIX_ID = "postcodeLevelMatrix";

const initialState = {
  postcodeDistrictLabels: false,
  postcodeSectorLables: false,
  postcodeUnitLabels: false,
  postcodeDistrictBoundaries: false,
  postcodeSectorBoundaries: false,
  postcodeUnitBoundaries: false,
  postcodeLevelMatrix: false
};

const sourcesReducer = createSlice({
  name: "sources",
  initialState,
  reducers: {
    setSourceLoaded: (state, action) => {
      state[action.payload] = true;
    },
    resetSources: state => {
      state[POSTCODE_DISTRICT_LABELS_ID] = false;
      state[POSTCODE_SECTOR_LABELS_ID] = false;
      state[POSTCODE_UNIT_LABELS_ID] = false;
      state[POSTCODE_DISTRICT_BOUNDARIES_ID] = false;
      state[POSTCODE_SECTOR_BOUNDARIES_ID] = false;
      state[POSTCODE_UNIT_BOUNDARIES_ID] = false;
      state[POSTCODE_LEVEL_MATRIX_ID] = false;
    }
  }
});

export const { setSourceLoaded, resetSources } = sourcesReducer.actions;
export default sourcesReducer.reducer;
