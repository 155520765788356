import {
  POSTCODE_DISTRICT_LABELS_ID,
  POSTCODE_SECTOR_LABELS_ID,
  POSTCODE_UNIT_LABELS_ID
} from "../store/reducers/sourcesReducer";

export const POSTCODE_DISTRICT_LABELS = {
  type: "vector",
  url: "mapbox://dpd-saturn-five.ddiwz11y"
};

export const POSTCODE_SECTOR_LABELS = {
  type: "vector",
  url: "mapbox://dpd-saturn-five.970oz4l3"
};

export const POSTCODE_UNIT_LABELS = {
  type: "vector",
  url: "mapbox://dpd-saturn-five.1qfedrkf"
};

export const POSTCODE_AREA_BOUNDARIES = {
  type: "vector",
  url: "mapbox://mapbox.boundaries-pos1-v3"
};

export const POSTCODE_DISTRICT_BOUNDARIES = {
  type: "vector",
  url: "mapbox://mapbox.boundaries-pos2-v3"
};

export const POSTCODE_SECTOR_BOUNDARIES = {
  type: "vector",
  url: "mapbox://mapbox.boundaries-pos3-v3"
};

export const POSTCODE_UNIT_BOUNDARIES = {
  type: "vector",
  url: "mapbox://mapbox.boundaries-pos4-v3"
};

export const LABEL_LAYERS = {
  DISTRICT: {
    id: "postcode-district-labels",
    sourceId: POSTCODE_DISTRICT_LABELS_ID,
    sourceLayer: "boundaries-pos2-LABELS_-_boun-5ppdk5"
  },
  SECTOR: {
    id: "postcode-sector-labels",
    sourceId: POSTCODE_SECTOR_LABELS_ID,
    sourceLayer: "boundaries-pos3-LABELS_-_boun-57n2d8"
  },
  UNIT: {
    id: "postcode-unit-labels",
    sourceId: POSTCODE_UNIT_LABELS_ID,
    sourceLayer: "out-6bu15c"
  }
};

export const depotData = {
  id: "root",
  name: "Depots",
  type: "root",
  children: [
    {
      id: "depot - 0030",
      name: "0030",
      latitude: "52.507370",
      longitude: "-2.011590",
      type: "depot",
      children: [
        {
          id: "0030-1",
          name: "000",
          latitude: "52.507390",
          longitude: "-2.011690",
          type: "route",
          children: [
            {
              id: "0030-000-1",
              name: "Stop 1",
              latitude: "52.508061",
              longitude: "-1.9673817",
              type: "stop",
              status: "delivered"
            },
            {
              id: "0030-000-2",
              name: "Stop 2",
              latitude: "52.505374",
              longitude: "-1.9585303",
              type: "stop",
              status: "delivered"
            },
            {
              id: "0030-000-3",
              name: "Stop 3",
              latitude: "52.504223",
              longitude: "-1.9541212",
              type: "stop"
            }
            // {
            //   id: "0030-000-4",
            //   name: "Stop 4",
            //   latitude: "52.516323",
            //   longitude: "-2.000777"
            // },
            // {
            //   id: "0030-000-5",
            //   name: "Stop 5",
            //   latitude: "52.522478",
            //   longitude: "-1.998953"
            // },
            // {
            //   id: "0030-000-6",
            //   name: "Stop 6",
            //   latitude: "52.522444",
            //   longitude: "-1.994559"
            // },
            // {
            //   id: "0030-000-7",
            //   name: "Stop 7",
            //   latitude: "52.525699",
            //   longitude: "-1.995718"
            // },
            // {
            //   id: "0030-000-8",
            //   name: "Stop 8",
            //   latitude: "52.527071",
            //   longitude: "-1.995938"
            // },
            // {
            //   id: "0030-000-9",
            //   name: "Stop 9",
            //   latitude: "52.533728",
            //   longitude: "-1.994344"
            // },
            // {
            //   id: "0030-000-10",
            //   name: "Stop 10",
            //   latitude: "52.541032",
            //   longitude: "52.541032, -1.990158"
            // }
          ]
        },
        {
          id: "0030-2",
          name: "301",
          latitude: "52.505948",
          longitude: "-2.006535",
          type: "route",
          children: [
            {
              id: "0030-301-1",
              name: "Stop 1",
              latitude: "52.309412",
              longitude: "-1.9860177",
              type: "stop"
            },
            {
              id: "0030-301-2",
              name: "Stop 2",
              latitude: "52.3144393",
              longitude: "-1.9639997",
              type: "stop"
            },
            {
              id: "0030-301-3",
              name: "Stop 3",
              latitude: "52.3129894",
              longitude: "-1.9628622",
              type: "stop"
            },
            {
              id: "0030-301-4",
              name: "Stop 4",
              latitude: "52.3120363",
              longitude: "-1.9553747",
              type: "stop"
            },
            {
              id: "0030-301-5",
              name: "Stop 5",
              latitude: "52.3097933",
              longitude: "-1.9542587",
              type: "stop"
            }
            // {
            //   id: "0030-301-6",
            //   name: "Stop 6",
            //   latitude: "",
            //   longitude: ""
            // },
            // {
            //   id: "0030-301-7",
            //   name: "Stop 7"
            // },
            // {
            //   id: "0030-301-8",
            //   name: "Stop 8"
            // },
            // {
            //   id: "0030-301-9",
            //   name: "Stop 9"
            // },
            // {
            //   id: "0030-301-10",
            //   name: "Stop 10"
            // }
          ]
        },
        {
          id: "0030-3",
          name: "311",
          latitude: "52.505948",
          longitude: "-2.005635",
          type: "route",
          children: [
            {
              id: "0030-311-1",
              name: "Stop 1",
              type: "stop"
            },
            {
              id: "0030-311-2",
              name: "Stop 2",
              type: "stop"
            },
            {
              id: "0030-311-3",
              name: "Stop 3",
              type: "stop"
            },
            {
              id: "0030-311-4",
              name: "Stop 4",
              type: "stop"
            },
            {
              id: "0030-311-5",
              name: "Stop 5",
              type: "stop"
            },
            {
              id: "0030-311-6",
              name: "Stop 6",
              type: "stop"
            },
            {
              id: "0030-311-7",
              name: "Stop 7",
              type: "stop"
            },
            {
              id: "0030-311-8",
              name: "Stop 8",
              type: "stop"
            },
            {
              id: "0030-311-9",
              name: "Stop 9",
              type: "stop"
            },
            {
              id: "0030-311-10",
              name: "Stop 10",
              type: "stop"
            }
          ]
        }
      ]
    },
    {
      id: "depot - 0056",
      name: "0056",
      latitude: "55.835690",
      longitude: "-4.066207",
      type: "depot",
      children: [
        {
          id: "0056-1",
          name: "000",
          latitude: "55.848797",
          longitude: "-4.0404135",
          type: "route",
          children: [
            {
              id: "0056-000-1",
              name: "Stop 1"
            },
            {
              id: "0056-000-2",
              name: "Stop 2"
            },
            {
              id: "0056-000-3",
              name: "Stop 3"
            },
            {
              id: "0056-000-4",
              name: "Stop 4"
            },
            {
              id: "0056-000-5",
              name: "Stop 5"
            },
            {
              id: "0056-000-6",
              name: "Stop 6"
            },
            {
              id: "0056-000-7",
              name: "Stop 7"
            },
            {
              id: "0056-000-8",
              name: "Stop 8"
            },
            {
              id: "0056-000-9",
              name: "Stop 9"
            },
            {
              id: "0056-000-10",
              name: "Stop 10"
            }
          ]
        },
        {
          id: "0056-2",
          name: "301",
          type: "route",
          latitude: "55.848787",
          longitude: "-4.0404145",
          children: [
            {
              id: "0056-301-1",
              name: "Stop 1"
            },
            {
              id: "0056-301-2",
              name: "Stop 2"
            },
            {
              id: "0056-301-3",
              name: "Stop 3"
            },
            {
              id: "0056-301-4",
              name: "Stop 4"
            },
            {
              id: "0056-301-5",
              name: "Stop 5"
            },
            {
              id: "0056-301-6",
              name: "Stop 6"
            },
            {
              id: "0056-301-7",
              name: "Stop 7"
            },
            {
              id: "0056-301-8",
              name: "Stop 8"
            },
            {
              id: "0056-301-9",
              name: "Stop 9"
            },
            {
              id: "0056-301-10",
              name: "Stop 10"
            }
          ]
        }
      ]
    }
  ]
};

export const drawerWidth = 240;
