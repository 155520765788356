import { groupBy } from "lodash";

export const BOUNDARY_PADDING = 0.5;
export const BOUNDARY_PADDING_ROUTE = 0.001;

export const getBoundingBoxFromGEOJsonFeatures = (
  places = [],
  padding = BOUNDARY_PADDING
) => {
  if (places.length) {
    const lat = [];
    const lng = [];
    places.forEach(place => {
      if (
        place.geometry.coordinates &&
        !isNaN(place.geometry.coordinates[0]) &&
        !isNaN(place.geometry.coordinates[1])
      ) {
        lng.push(place.geometry.coordinates[0]);
        lat.push(place.geometry.coordinates[1]);
      }
    });
    if (!lat.length || !lng.length) {
      return;
    }
    return [
      {
        lng: Math.min(...lng) - padding,
        lat: Math.min(...lat) - padding
      },
      {
        lng: Math.max(...lng) + padding,
        lat: Math.max(...lat) + padding
      }
    ];
  }
};

export const getBoundingBoxFromCoordinatesArray = (coordinates = []) => {
  if (!coordinates.length) {
    return;
  }

  const lat = [];
  const lng = [];
  coordinates.forEach(coord => {
    if (!isNaN(coord[0]) || !isNaN(coord[1])) {
      lng.push(coord[0]);
      lat.push(coord[1]);
    }
  });
  return [
    {
      lng: Math.min(...lng) - BOUNDARY_PADDING_ROUTE,
      lat: Math.min(...lat) - BOUNDARY_PADDING_ROUTE
    },
    {
      lng: Math.max(...lng) + BOUNDARY_PADDING_ROUTE,
      lat: Math.max(...lat) + BOUNDARY_PADDING_ROUTE
    }
  ];
};

export const findNested = (obj, value, key = "name") => {
  const flatObject = flattenObject(obj);
  const filtered = flatObject.filter(item => item[key].includes(value));
  const grouped = groupBy(filtered, "type");
  return {
    type: "root",
    name: "Search Result",
    container: true,
    id: "root",
    children: Object.keys(grouped).map(key => {
      console.log(grouped[key]);
      delete grouped[key].children;
      return {
        type: key,
        name: capitalizeFirstLetter(key),
        id: key,
        container: true,
        children: grouped[key]
      };
    })
  };
};

export const flattenObject = (obj, list = []) => {
  if (obj.children) {
    obj.children.forEach(item => {
      flattenObject(item, list);
      list.push(item);
    });
  }
  return list;
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
