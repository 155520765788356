import boundariesArea from "./lookupTables/boundaries-pos1-v3.json";
import boundariesOuter from "./lookupTables/boundaries-pos2-v3.json";
import boundariesPostcode from "./lookupTables/boundaries-pos4-v3.json";
import boundariesSector from "./lookupTables/boundaries-pos3-v3.json";
import { isEqual } from "lodash";
import sectorPlusOne from "./lookupTables/boundaries-sectorplusone.json";

export const getNewTable = level => {
  if (level === 0) {
    return boundariesArea;
  } else if (level === 1) {
    return boundariesOuter;
  } else if (level === 2) {
    return boundariesSector;
  } else {
    return boundariesPostcode;
  }
};

export const getSimilarFeatures = ({
  postcode,
  level,
  matrixTemplate,
  type
}) => {
  const line = matrixTemplate.filter(m => {
    if (level === 0) {
      return m.outer === postcode && m.sector === "";
    } else if (level === 1) {
      return m.sector === postcode && m.sector_plus1 === "";
    } else if (level === 2) {
      return m.sector_plus1 === postcode && m.postcode === "";
    } else {
      return m.postcode === postcode;
    }
  });

  if (!line[0]) {
    return [];
  }

  const matchingPostcodes = matrixTemplate.filter(
    m =>
      isEqual(m[type], line[0][type]) &&
      ((level === 0 && m.sector === "" && m.outer !== line[0].outer) ||
        (level === 1 &&
          m.sector_plus1 === "" &&
          m.sector !== "" &&
          m.sector !== line[0].sector) ||
        (level === 2 &&
          m.postcode === "" &&
          m.sector_plus1 !== "" &&
          m.sector_plus1 !== line[0].sector_plus1) ||
        (level === 3 && m.postcode !== line[0].postcode))
  );

  return matchingPostcodes.map(
    p => p.postcode || p.sector_plus1 || p.sector || p.outer
  );
};

export const getSectorPlusOne = () => {
  const colours = [];
  Object.values(sectorPlusOne).forEach(s => {
    colours.push(["in", ["id"], ["literal", s.features]]);
    colours.push(s.color);
  });

  return colours;
};
