import * as constants from "./constants";
import { findNested } from "./utils";
import { depotData } from "../constants/constants";

export const setTreeData = payload => {
  return {
    payload,
    type: constants.SET_TREE_DATA
  };
};

export const setInfoData = payload => {
  return {
    payload,
    type: constants.SET_INFO_DATA
  };
};

export const toggleSideBar = () => {
  return {
    type: constants.TOGGLE_SIDE_BAR
  };
};

export const changeTab = payload => {
  return {
    payload,
    type: constants.CHANGE_TAB
  };
};

export const filterTreeData = value => {
  return async (dispatch, getState) => {
    if (!value) {
      dispatch(setTreeData(depotData));
    } else {
      const { sideBarReducer } = getState();
      const filtered = findNested(sideBarReducer.treeData, value);
      dispatch(setTreeData(filtered));
    }
  };
};
