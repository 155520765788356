import {
  CheckboxWithLabel,
  Collapsible
} from "@dpdgroupuk/central-collections-common";
import {
  setDeliveryRoutesMultiMap,
  toggleAllowOverlap
} from "../../store/reducers/multiMapReducer";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@material-ui/core";
import React from "react";

export const SidePanel = () => {
  const { deliveryRoutes, allowOverlap } = useSelector(
    state => state.multiMapReducer
  );
  const dispatch = useDispatch();

  return (
    <>
      <CheckboxWithLabel
        check={allowOverlap}
        label={"Allow Labels Overlap"}
        onChange={() => dispatch(toggleAllowOverlap())}
      />
      {Object.keys(deliveryRoutes.waves).map((w, k) => {
        return (
          <CheckboxWithLabel
            key={k}
            check={deliveryRoutes.waves[w].enabled}
            label={deliveryRoutes.waves[w].name}
            onChange={() =>
              dispatch(
                setDeliveryRoutesMultiMap({
                  waves: {
                    ...deliveryRoutes.waves,
                    [w]: {
                      ...deliveryRoutes.waves[w],
                      enabled: !deliveryRoutes.waves[w].enabled
                    }
                  }
                })
              )
            }
          />
        );
      })}
      <Collapsible
        title={"Route Colours"}
        headerTypographyVariant={"body1"}
        border={false}
        defaultCollapsed={true}
      >
        <Grid container>
          {deliveryRoutes.routeColor &&
            Object.keys(deliveryRoutes.routeColor).map((route, index) => (
              <Grid key={index} item xs={12} container>
                <Grid
                  item
                  xs={2}
                  style={{
                    borderBottom: "1px solid black",
                    backgroundColor: deliveryRoutes.routeColor[route],
                    opacity: 0.2
                  }}
                />
                <Grid item xs={10}>
                  {route}
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Collapsible>
    </>
  );
};
