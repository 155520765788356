import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mainLayer: { display: false },
  labelsLayer: { display: true },
  matrixTemplateLayer: { display: false },
  matrixH3Layer: { display: false },
  collectionsLayer: { display: false },
  boundariesLayer: { display: true },
  collectionsHeatmapLayer: { display: false },
};

const layersReducer = createSlice({
  name: "layers",
  initialState,
  reducers: {
    toggleMainLayer: state => {
      state.mainLayer.display = !state.mainLayer.display;
    },
    toggleLabelLayer: state => {
      state.labelsLayer.display = !state.labelsLayer.display;
    },
    toggleMatrixTemplateLayer: state => {
      state.matrixTemplateLayer.display = !state.matrixTemplateLayer.display;
    },
    toggleMatrixH3Layer: state => {
      console.log("toggle matrix h3 layer");
      state.matrixH3Layer.display = !state.matrixH3Layer.display;
    },
    toggleCollectionsLayer: state => {
      state.collectionsLayer.display = !state.collectionsLayer.display;
    },
    toggleBoundariesLayer: state => {
      state.boundariesLayer.display = !state.boundariesLayer.display;
    },
    toggleCollectionsHeatmapLayer: state => {
      state.collectionsHeatmapLayer.display = !state.collectionsHeatmapLayer.display;
    }
  }
});

export const {
  toggleMainLayer,
  toggleLabelLayer,
  toggleMatrixTemplateLayer,
  toggleMatrixH3Layer,
  toggleCollectionsLayer,
  toggleBoundariesLayer,
  toggleCollectionsHeatmapLayer
} = layersReducer.actions;
export default layersReducer.reducer;
