import React from "react";
import { GeoJSONLayer } from "react-mapbox-gl";
import { useSelector, useDispatch } from "react-redux";
import { setInfoData, toggleSideBar } from "../actions/sideBarActions";

export const MainLayer = () => {
  const state = useSelector(state => state.mapReducer);
  const isSideBarOpen = useSelector(state => state.sideBarReducer.isOpen);
  const dispatch = useDispatch();

  const symbolOnClick = event => {
    dispatch(setInfoData(event.features[0]));
    if (!isSideBarOpen) {
      dispatch(toggleSideBar());
    }
  };

  return (
    <GeoJSONLayer
      data={state.mainLayerData}
      symbolLayout={{
        "icon-image": ["get", "icon"],
        "icon-size": ["get", "icon-size"],
        "icon-allow-overlap": true,
        "icon-anchor": "bottom",
        "text-allow-overlap": true,
        "text-field": "{name}",
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-anchor": ["get", "text-anchor"],
        "text-offset": ["get", "text-offset"]
      }}
      symbolPaint={{
        "text-color": ["get", "text-color"],
        "icon-color": [
          "match",
          ["get", "status"],
          "delivered",
          "#00b300",
          "#000000"
        ]
      }}
      linePaint={{
        "line-color": "#3887be",
        "line-width": 5,
        "line-opacity": 0.75
      }}
      symbolOnClick={symbolOnClick}
    />
  );
};
