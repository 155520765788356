import React, { useEffect, useState } from "react";
import { setMapCenter, zoomChanged } from "../../store/reducers/zoomReducer";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@material-ui/core";
import { SingleMap } from "./SingleMap";
import { getNewTable } from "../Boundaries/utils";
import randomColor from "randomcolor";
import { resetSources } from "../../store/reducers/sourcesReducer";
import { setDeliveryRoutesMultiMap } from "../../store/reducers/multiMapReducer";

export const MultiMap = () => {
  const { deliveryRoutes } = useSelector(state => state.multiMapReducer);
  const { level } = useSelector(state => state.zoomReducer);
  const { matrixPostcodeMapping, mapping } = useSelector(
    state => state.matrixTemplateReducer
  );
  const [selectedWave, setSelectedWave] = useState();
  const [featureId, setFeatureId] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(zoomChanged(12));
    dispatch(
      setMapCenter({ lng: -2.0902083267978924, lat: 52.46983052836353 })
    );
    dispatch(resetSources());
  }, []);

  useEffect(() => {
    if (deliveryRoutes.enabled && matrixPostcodeMapping && mapping) {
      enableDeliveryRoutes();
    }
  }, [level, deliveryRoutes.enabled, matrixPostcodeMapping, mapping]);

  const enableDeliveryRoutes = () => {
    const deliveryToFeature = {};
    const deliveryToLabel = {};
    const routeColor = {};
    if (deliveryRoutes.enabled) {
      const lines =
        matrixPostcodeMapping[level === 3 || level === 0 ? level : level - 1];
      const routes = [
        ...new Set(
          Object.values(lines)
            .map(v => v.delivery_route)
            .flat()
        )
      ];
      const colors = randomColor({ count: routes.length });
      routes.forEach((r, i) => {
        routeColor[r] = colors[i];
      });
      const lookupTable = getNewTable(level)[`pos${level + 1}`].data.all;
      Object.keys(lines).forEach(key => {
        const feature =
          lookupTable[`GBP${level + 1}${key.replace(" ", "").toUpperCase()}`];
        const waves = {};
        lines[key].delivery_route.forEach((r, i) => {
          waves[`delivery_${i}_route`] = r;
          waves[`delivery_${i}_color`] = routeColor[r];
        });
        deliveryToFeature[feature?.feature_id || -1] = waves;

        deliveryToLabel[mapping.postcodeToFeature[key]] = waves;
      });
    }
    dispatch(
      setDeliveryRoutesMultiMap({
        routes: deliveryToFeature,
        routeColor,
        labels: deliveryToLabel
      })
    );
  };

  const enabledRoutes = Object.values(deliveryRoutes.waves).filter(
    w => w.enabled
  );

  return (
    <Grid container style={{ height: "100%" }}>
      {enabledRoutes.map((wave, index) => (
        <Grid
          key={wave.name}
          style={{
            border: selectedWave === wave.name ? "1px solid red" : ""
          }}
          item
          xs={6}
        >
          <SingleMap
            wave={wave}
            highlightFeature={featureId}
            waveNumber={wave.wave}
            selected={selectedWave ? selectedWave === wave.name : index === 0}
            onMouseMove={features => {
              if (selectedWave !== wave.name) {
                setSelectedWave(wave.name);
              }
              if (features.length > 0) {
                setFeatureId(features[0].id);
              }
            }}
            onDrag={c => {
              console.log(c);
              if (selectedWave === wave.name) {
                dispatch(setMapCenter(c));
              }
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
