import { Layer, Feature } from "react-mapbox-gl";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const h3 = require("h3-js");

const layerPaint = {
  // Increase the heatmap weight based on frequency and property magnitude
  'heatmap-weight': [
    'interpolate',
    ['linear'],
    ['get', 'collections'],
    0,
    0,
    6,
    1
  ],
  // Increase the heatmap color weight weight by zoom level
  // heatmap-intensity is a multiplier on top of heatmap-weight
  'heatmap-intensity': [
    'interpolate',
    ['linear'],
    ['zoom'],
    0,
    1,
    20,
    3
  ],
  // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
  // Begin color ramp at 0-stop with a 0-transparancy color
  // to create a blur-like effect.
  'heatmap-color': [
    'interpolate',
    ['linear'],
    ['heatmap-density'],
    0,
    'rgba(33,102,172,0)',
    0.2,
    'rgb(103,169,207)',
    0.4,
    'rgb(209,229,240)',
    0.6,
    'rgb(253,219,199)',
    0.8,
    'rgb(239,138,98)',
    1,
    'rgb(178,24,43)'
  ],
  // Adjust the heatmap radius by zoom level
  'heatmap-radius': [
    'interpolate',
    ['linear'],
    ['zoom'],
    0,
    2,
    20,
    20
  ],
  // Transition from heatmap to circle layer by zoom level
  'heatmap-opacity': [
    'interpolate',
    ['linear'],
    ['zoom'],
    7,
    1,
    20,
    0
  ]
};

export const CollectionsHeatmap = () => {
  const { collections, hexLevel, scale } = useSelector(
    state => state.collectionsReducer
  );
  const [features, setFeatures] = useState();

  useEffect(() => {
    if (!collections) {
      return;
    }

    let byH3Hex = {};

    collections.forEach((c => {
      const h = h3.geoToH3(c.latitude, c.longitude, 14);

      if (!byH3Hex[h]) {
        byH3Hex[h] = 1;
      } else {
        byH3Hex[h] ++;
      }
    }));

    let features = Object.keys(byH3Hex).map((key) => {
      let [lat, lng] = h3.h3ToGeo(key);

      return {
        latlng: [lng, lat],
        collections: byH3Hex[key]
      };
    });

    setFeatures(features);
  }, [collections]);

  return (
    <>
      {features && (
        <>
          <Layer type="heatmap" paint={layerPaint}>
            {features.map((el, index) => (
              <Feature key={index} coordinates={el.latlng} properties={el} />
            ))}
          </Layer>
        </>
      )}
    </>
  );
};
