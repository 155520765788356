import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collections: null,
  hexLevel: 9,
  scale: 1
};

const collectionsReducer = createSlice({
  name: "collections",
  initialState,
  reducers: {
    setCollections: (state, action) => {
      state.collections = action.payload;
    },
    setHexLevel: (state, action) => {
      state.hexLevel = parseInt(action.payload, 10);
    },
    setScale: (state, action) => {
      state.scale = parseInt(action.payload, 10);
    }
  }
});

export const {
  setCollections,
  setHexLevel,
  setScale
} = collectionsReducer.actions;
export default collectionsReducer.reducer;
