import { createSlice } from "@reduxjs/toolkit";
import { getZoomLevel } from "../../components/MatrixTemplate/utils";

const initialState = {
  zoom: null,
  level: 0,
  center: { lng: -2.36967, lat: 54.237933 }
};

const zoomReducer = createSlice({
  name: "zoom",
  initialState,
  reducers: {
    zoomChanged: (state, action) => {
      state.zoom = action.payload;
      const newLevel = getZoomLevel(action.payload);
      if (newLevel !== state.level) {
        state.level = newLevel;
      }
    },
    setMapCenter: (state, action) => {
      state.center = action.payload;
    }
  }
});

export const { zoomChanged, setMapCenter } = zoomReducer.actions;
export default zoomReducer.reducer;
