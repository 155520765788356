import React from "react";
import { makeStyles } from "@material-ui/core";
import { TreeItem, TreeView } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DepotViewController from "./DepotViewController";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  treeView: {
    padding: 10
  }
});

const DepotView = () => {
  const classes = useStyles();
  const controller = new DepotViewController();
  const treeData = useSelector(state => state.sideBarReducer.treeData);

  const renderTree = nodes => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
      onLabelClick={() => {
        controller.clickTree(nodes);
      }}
      onClick={() => controller.onClickDepot(nodes)}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map(node => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <TreeView
      className={classes.treeView}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={["root"]}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {renderTree(treeData)}
    </TreeView>
  );
};

export default DepotView;
