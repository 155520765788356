import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  postcodeGeoJson: null,
  postcodeLabels: {},
  matrixTemplate: null,
  matrixPostcodeMapping: null,
  mapping: null,
  onHover: false,
  onClick: false,
  searchInput: "",
  allowOverlap: false,
  highlightSimilar: {
    enabled: false,
    type: "delivery_route",
    colour: "#ff0000",
    hex: { r: 255, g: 0, b: 0, a: 1 }
  },
  allocation: {
    enabled: false,
    type: "bulk_route",
    routes: [300, 301, 302, 303, 304],
    selectedRoute: null
  },
  sidePanel: {
    open: false,
    selectedFeature: null
  }
};

const matrixTemplateReducer = createSlice({
  name: "matrixTemplate",
  initialState,
  reducers: {
    setPostcodeGeoJson: (state, action) => {
      state.postcodeGeoJson = action.payload;
      state.postcodeLabels[0] = action.payload.features.filter(
        f => f.properties.level === 0
      );
      state.postcodeLabels[1] = action.payload.features.filter(
        f => f.properties.level === 1
      );
      state.postcodeLabels[2] = action.payload.features.filter(
        f => f.properties.level === 2
      );
      state.postcodeLabels[3] = action.payload.features.filter(
        f => f.properties.level === 3
      );
    },
    setMatrixTemplate: (state, action) => {
      state.matrixTemplate = action.payload;
      const matrix = {
        0: {},
        1: {},
        2: {},
        3: {}
      };
      action.payload.forEach(line => {
        if (line.postcode) {
          matrix[3][line.postcode] = line;
        } else if (line.sector_plus1) {
          matrix[2][line.sector_plus1] = line;
        } else if (line.sector) {
          matrix[1][line.sector] = line;
        } else {
          matrix[0][line.outer] = line;
        }
      });
      state.matrixPostcodeMapping = matrix;
    },
    setMapping: (state, action) => {
      state.mapping = action.payload;
    },
    toggleOnHover: state => {
      state.onHover = !state.onHover;
    },
    toggleOnClick: state => {
      state.onClick = !state.onClick;
    },
    toggleAllowOverlap: state => {
      state.allowOverlap = !state.allowOverlap;
    },
    setSearchInput: (state, action) => {
      state.searchInput = action.payload;
    },
    setHighlightSimilar: (state, action) => {
      state.highlightSimilar = { ...state.highlightSimilar, ...action.payload };
    },
    setAllocation: (state, action) => {
      state.allocation = { ...state.allocation, ...action.payload };
    },
    setSidePanel: (state, action) => {
      state.sidePanel = { ...state.sidePanel, ...action.payload };
    }
  }
});

export const {
  setPostcodeGeoJson,
  setMatrixTemplate,
  setMapping,
  toggleOnHover,
  toggleOnClick,
  setSearchInput,
  setHighlightSimilar,
  toggleAllowOverlap,
  setAllocation,
  setSidePanel
} = matrixTemplateReducer.actions;
export default matrixTemplateReducer.reducer;
