import {
  POSTCODE_DISTRICT_BOUNDARIES_ID,
  POSTCODE_LEVEL_MATRIX_ID,
  POSTCODE_SECTOR_BOUNDARIES_ID,
  POSTCODE_UNIT_BOUNDARIES_ID
} from "../../store/reducers/sourcesReducer";
import React, { useEffect } from "react";

import { LABEL_LAYERS } from "../../constants/constants";
import { Layer } from "react-mapbox-gl";
import { useSelector } from "react-redux";

export const sourceMapping = {
  0: "postcode_area_boundaries",
  1: POSTCODE_DISTRICT_BOUNDARIES_ID,
  2: POSTCODE_SECTOR_BOUNDARIES_ID,
  3: POSTCODE_UNIT_BOUNDARIES_ID
};

export const DeliveryRoutes = ({ map }) => {
  const { matrixTemplateLayer } = useSelector(state => state.layersReducer);
  const sources = useSelector(state => state.sourcesReducer);
  const { deliveryRoutes } = useSelector(state => state.boundariesReducer);
  const { level } = useSelector(state => state.zoomReducer);

  useEffect(() => {
    if (!map || !deliveryRoutes.routes) {
      return;
    }
    console.log("setting state");
    Object.keys(deliveryRoutes.routes).forEach(id => {
      map.setFeatureState(
        {
          source: sourceMapping[level],
          sourceLayer: `boundaries_postal_${level + 1}`,
          id
        },
        deliveryRoutes.routes[id]
      );
    });
  }, [deliveryRoutes.routes, map]);

  const getCommonProperties = wave => {
    return {
      filter: ["==", ["get", "iso_3166_1"], "GB"],
      type: "fill",
      paint: {
        "fill-color": ["feature-state", `delivery_${wave}_color`],
        "fill-opacity": deliveryRoutes.waves[wave].opacity,
        "fill-outline-color": "rgba(0, 0, 0, 0.6)"
      }
    };
  };

  return (
    <>
      {Object.keys(deliveryRoutes.waves).map(wave => {
        if (!deliveryRoutes.waves[wave].enabled) {
          return null;
        }

        return (
          <>
            <Layer
              id={`postcode-district-boundaries-delivery-${wave}`}
              sourceId={POSTCODE_DISTRICT_BOUNDARIES_ID}
              sourceLayer={`boundaries_postal_2`}
              maxZoom={10}
              before={
                matrixTemplateLayer.display
                  ? sources[POSTCODE_LEVEL_MATRIX_ID] && "template-layer"
                  : (map &&
                      map.getLayer(LABEL_LAYERS["DISTRICT"].id) &&
                      LABEL_LAYERS["DISTRICT"].id) ||
                    ""
              }
              {...getCommonProperties(wave)}
            />
            <Layer
              id={`postcode-sector-boundaries-delivery-${wave}`}
              sourceId={POSTCODE_SECTOR_BOUNDARIES_ID}
              sourceLayer={`boundaries_postal_3`}
              minZoom={10}
              maxZoom={14}
              before={
                matrixTemplateLayer.display
                  ? sources[POSTCODE_LEVEL_MATRIX_ID] && "template-layer"
                  : (map &&
                      map.getLayer(LABEL_LAYERS["SECTOR"].id) &&
                      LABEL_LAYERS["SECTOR"].id) ||
                    ""
              }
              {...getCommonProperties(wave)}
            />
            <Layer
              id={`postcode-unit-boundaries-delivery-${wave}`}
              sourceId={POSTCODE_UNIT_BOUNDARIES_ID}
              sourceLayer={`boundaries_postal_4`}
              minZoom={14}
              before={
                matrixTemplateLayer.display
                  ? sources[POSTCODE_LEVEL_MATRIX_ID] && "template-layer"
                  : (map &&
                      map.getLayer(LABEL_LAYERS["UNIT"].id) &&
                      LABEL_LAYERS["UNIT"].id) ||
                    ""
              }
              {...getCommonProperties(wave)}
            />
          </>
        );
      })}
    </>
  );
};
