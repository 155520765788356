import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  List,
  ListItem,
  Link,
  Typography,
  ListItemText,
  Collapse,
  makeStyles
} from "@material-ui/core";
import { setInfoData } from "../actions/sideBarActions";
import { capitalizeFirstLetter } from "../actions/utils";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { StopsList } from "./RouteInfoView/StopsList";
import { getRouteDetails } from "../actions";

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

export const InfoView = () => {
  const classes = useStyles();
  const info = useSelector(state => state.sideBarReducer.infoData);
  const [coordinatesOpen, setCoordinatesOpen] = useState(false);
  const dispatch = useDispatch();

  const onClickBack = event => {
    event.preventDefault();
    dispatch(setInfoData());
  };

  const handleCoordinatesClick = () => [setCoordinatesOpen(!coordinatesOpen)];

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const movedItem = info.properties.children.filter(
      item => item.id === draggableId
    )[0];

    const myList = [...info.properties.children];
    myList.splice(source.index, 1);
    myList.splice(destination.index, 0, movedItem);

    const newInfo = {
      ...info,
      properties: {
        ...info.properties,
        children: myList
      }
    };
    dispatch(setInfoData(newInfo));
    dispatch(getRouteDetails(newInfo.properties));
  };

  return (
    <List>
      <ListItem>
        <Link href="#" to="/" onClick={onClickBack} underline={"always"}>
          Go Back
        </Link>
      </ListItem>
      <ListItem>
        <Typography variant={"h5"}>
          {typeof info.properties.name === "string"
            ? capitalizeFirstLetter(info.properties.name)
            : info.properties.name}
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemText disableTypography={true}>
          <Typography variant={"subtitle2"}>
            {typeof info.properties.name === "string"
              ? capitalizeFirstLetter(info.properties.type)
              : info.properties.type}
          </Typography>
        </ListItemText>
      </ListItem>
      <ListItem onClick={handleCoordinatesClick}>
        <ListItemText primary="Coordinates" />
        {coordinatesOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={coordinatesOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemText
              primary={`Longitude ${info.geometry.coordinates[0]}`}
            />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemText
              primary={`Latitude ${info.geometry.coordinates[1]}`}
            />
          </ListItem>
        </List>
      </Collapse>

      {info.properties.type === "route" && (
        <StopsList stops={info.properties.children} onDragEnd={onDragEnd} />
      )}
    </List>
  );
};
