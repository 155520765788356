import {
  POSTCODE_DISTRICT_BOUNDARIES_ID,
  POSTCODE_SECTOR_BOUNDARIES_ID,
  POSTCODE_UNIT_BOUNDARIES_ID
} from "../../store/reducers/sourcesReducer";
import React, { useEffect, useState } from "react";

import { Layer } from "react-mapbox-gl";
import { useSelector } from "react-redux";

export const BoundariesSingleMap = ({ wave }) => {
  const [labelsColors, setLabelsColors] = useState([]);
  const { postcodeMapping } = useSelector(
    state => state.postcodeAllocationReducer
  );
  const sources = useSelector(state => state.sourcesReducer);

  useEffect(() => {
    if (!postcodeMapping) {
      return;
    }
    const array = Object.keys(postcodeMapping).map(key => [
      key,
      postcodeMapping[key].color
    ]);
    setLabelsColors([].concat(...array));
  }, [postcodeMapping]);

  const common = {
    filter: ["==", ["get", "iso_3166_1"], "GB"],
    type: "fill",
    paint: {
      "fill-color": [
        "feature-state",
        `delivery_${wave}_color`
        // "match",
        // ["feature-state", "unit_code"],
        // ...labelsColors,
        // "#E5E5FF"
      ],
      "fill-opacity": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        0.8,
        0.2
      ],
      "fill-outline-color": "rgba(0, 0, 255, 0.6)"
    }
  };

  return (
    <>
      {sources[POSTCODE_DISTRICT_BOUNDARIES_ID] && (
        <Layer
          id={"postcode-district-boundaries"}
          sourceId={POSTCODE_DISTRICT_BOUNDARIES_ID}
          sourceLayer={`boundaries_postal_2`}
          maxZoom={10}
          before={"template-layer"}
          {...common}
        />
      )}
      {sources[POSTCODE_SECTOR_BOUNDARIES_ID] && (
        <Layer
          id={"postcode-sector-boundaries"}
          sourceId={POSTCODE_SECTOR_BOUNDARIES_ID}
          sourceLayer={`boundaries_postal_3`}
          minZoom={10}
          maxZoom={14}
          before={"template-layer"}
          {...common}
        />
      )}
      {sources[POSTCODE_UNIT_BOUNDARIES_ID] && (
        <Layer
          id={"postcode-unit-boundaries"}
          sourceId={POSTCODE_UNIT_BOUNDARIES_ID}
          sourceLayer={`boundaries_postal_4`}
          minZoom={14}
          before={"template-layer"}
          {...common}
        />
      )}
    </>
  );
};
