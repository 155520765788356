import { Grid, Paper } from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { DateTime } from "luxon";
import React from "react";
import { RowDetails } from "@dpdgroupuk/central-collections-common";

export const TemplatePopUpDeckGl = ({ properties }) => {
  return (
    <Paper style={{ width: "300px", padding: "10px" }}>
      <Grid container>
        <Grid item xs={12}>
          <h1>
            {properties.postcode ||
              properties.sector_plus1 ||
              properties.sector ||
              properties.outer}

            {properties.inherited && " - Inherited"}
          </h1>
        </Grid>
        <Grid item xs={12}>
          <RowDetails
            title={"Delivery Routes"}
            value={properties?.delivery_route}
          />
        </Grid>
        <Grid item xs={12}>
          <RowDetails title={"Small Routes"} value={properties?.smalls_route} />
        </Grid>
        <Grid item xs={12}>
          <RowDetails title={"Bulk Route"} value={properties?.bulk_route} />
        </Grid>
        <Grid item xs={12}>
          <RowDetails
            title={"Collection Routes"}
            value={properties?.collection_route}
          />
        </Grid>
        <Grid item xs={12}>
          <RowDetails
            title={"Latest Ready Time"}
            value={
              properties && properties.latest_ready_time !== "null"
                ? DateTime.fromJSDate(
                    new Date(properties.latest_ready_time)
                  ).toFormat("HH:mm")
                : null
            }
          />
        </Grid>
        <Grid item xs={12}>
          <RowDetails
            title={"Same Day Cut Off"}
            value={
              properties && properties.same_day_cut_off !== "null"
                ? DateTime.fromJSDate(
                    new Date(properties.same_day_cut_off)
                  ).toFormat("HH:mm")
                : null
            }
          />
        </Grid>
        <Grid item xs={12}>
          <RowDetails
            title={"Saturn Auto"}
            value={
              properties && properties.saturn_auto ? (
                <CheckIcon />
              ) : (
                <ClearIcon />
              )
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
