import {
  POSTCODE_DISTRICT_LABELS_ID,
  POSTCODE_SECTOR_LABELS_ID,
  POSTCODE_UNIT_LABELS_ID
} from "../store/reducers/sourcesReducer";

import { LABEL_LAYERS } from "../constants/constants";
import { Layer } from "react-mapbox-gl";
import React from "react";
import { useSelector } from "react-redux";

let selectedElement;

export const PostcodeLabels = ({ map }) => {
  const sources = useSelector(state => state.sourcesReducer);

  return (
    <>
      {sources[POSTCODE_DISTRICT_LABELS_ID] && (
        <Layer
          id={LABEL_LAYERS["DISTRICT"].id}
          sourceId={LABEL_LAYERS["DISTRICT"].sourceId}
          sourceLayer={LABEL_LAYERS["DISTRICT"].sourceLayer}
          sho
          type={"symbol"}
          layout={{
            "text-field": ["get", "unit_code"],
            "text-size": 12
          }}
          maxZoom={10}
          onMouseEnter={e => {
            selectedElement = e;
            map.setFeatureState(
              {
                source: POSTCODE_DISTRICT_LABELS_ID,
                sourceLayer: "boundaries-pos2-LABELS_-_boun-5ppdk5",
                id: e.features[0].id
              },
              { hover: "true" }
            );
          }}
          onMouseLeave={e => {
            if (selectedElement) {
              map.setFeatureState(
                {
                  source: POSTCODE_DISTRICT_LABELS_ID,
                  sourceLayer: "boundaries-pos2-LABELS_-_boun-5ppdk5",
                  id: selectedElement.features[0].id
                },
                { hover: "false" }
              );
            }

            selectedElement = null;
          }}
          paint={{
            "text-color": [
              "match",
              ["feature-state", "hover"],
              "true",
              "#00b300",
              /* other */ "#000"
            ]
          }}
          onMouseMove={e => {
            if (map) {
              const features = map.queryRenderedFeatures(e.point);
              // console.log(features);
            }
          }}
          // filter={['==', 'unit_code', 'LL35']}
          // filter={['match', ['get', 'unit_code'], ['LL35', 'LL14'], true, false]}
        />
      )}
      {sources[POSTCODE_SECTOR_LABELS_ID] && (
        <Layer
          id={LABEL_LAYERS["SECTOR"].id}
          sourceId={LABEL_LAYERS["SECTOR"].sourceId}
          sourceLayer={LABEL_LAYERS["SECTOR"].sourceLayer}
          type={"symbol"}
          layout={{
            "text-field": ["get", "unit_code"],
            "text-size": 12,
            "text-allow-overlap": true
          }}
          paint={{
            "text-color": [
              "match",
              ["feature-state", "hover"],
              "true",
              "#00b300",
              /* other */ "#000"
            ]
          }}
          minZoom={10}
          maxZoom={14}
          onMouseMove={e => {
            if (map) {
              const features = map.queryRenderedFeatures(e.point);
              // console.log(features);
            }
          }}
        />
      )}
      {sources[POSTCODE_UNIT_LABELS_ID] && (
        <Layer
          id={LABEL_LAYERS["UNIT"].id}
          sourceId={LABEL_LAYERS["UNIT"].sourceId}
          sourceLayer={LABEL_LAYERS["UNIT"].sourceLayer}
          type={"symbol"}
          layout={{
            "text-field": ["get", "unit_code"],
            "text-size": 12
          }}
          paint={{
            "text-color": [
              "match",
              ["feature-state", "hover"],
              "true",
              "#00b300",
              /* other */ "#000"
            ]
          }}
          minZoom={14}
        />
      )}
    </>
  );
};
