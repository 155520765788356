import { Layer, Source } from "react-mapbox-gl";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LABEL_LAYERS } from "../../constants/constants";
import { cloneDeep } from "lodash";
import { getZoomLevel } from "../MatrixTemplate/utils";
import { setPostcodeGeoJson } from "../../store/reducers/matrixTemplateReducer";

export const Labels = ({ waveIndex, map }) => {
  const { postcodeGeoJson, mapping } = useSelector(
    state => state.matrixTemplateReducer
  );
  const [routesGeoJson, setRoutesGeoJson] = useState();
  const { deliveryRoutes, allowOverlap } = useSelector(
    state => state.multiMapReducer
  );
  const [labelsLoaded, setLabelsLoaded] = useState(false);
  const { level } = useSelector(state => state.zoomReducer);

  useEffect(() => {
    if (!map || !deliveryRoutes.labels) {
      return;
    }
    const newGeoJson = [];
    Object.keys(deliveryRoutes.labels).forEach(id => {
      if (!postcodeGeoJson.features[id]) {
        return;
      }
      newGeoJson.push({
        ...postcodeGeoJson.features[id],
        properties: { ...deliveryRoutes.labels[id] }
      });
    });

    setRoutesGeoJson({ type: "FeatureCollection", features: newGeoJson });
  }, [deliveryRoutes.labels, map]);

  return (
    <>
      <Source
        id={"template"}
        geoJsonSource={{ data: postcodeGeoJson, type: "geojson" }}
      />
      {routesGeoJson && (
        <Source
          id={"routes"}
          geoJsonSource={{ data: routesGeoJson, type: "geojson" }}
          onSourceLoaded={() => setLabelsLoaded(true)}
        />
      )}

      <Layer
        id={"template-layer"}
        type={"symbol"}
        sourceId={"template"}
        layout={{
          "text-field": ["get", "name"],
          "text-allow-overlap": allowOverlap,
          "text-size": 11,
          "text-anchor": "bottom"
        }}
        filter={["==", "level", level > 0 ? level - 1 : level]}
        paint={{
          "text-color": [
            "match",
            ["feature-state", "hover"],
            "true",
            "#00b300",
            "#000"
          ]
        }}
      />
      {labelsLoaded && (
        <Layer
          id={"routes-layer"}
          type={"symbol"}
          sourceId={"routes"}
          layout={{
            "text-field": ["get", `delivery_${waveIndex}_route`],
            "text-anchor": "top",
            "text-size": 11,
            "text-allow-overlap": allowOverlap
          }}
        />
      )}
    </>
  );
};
