import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import React, { useState } from "react";

import DrawControl from "react-mapbox-gl-draw";
import bbox from "@turf/bbox";
import booleanContains from "@turf/boolean-contains";
import booleanOverlap from "@turf/boolean-overlap";

let draw = null;

export const DrawControls = ({ map }) => {
  const getFeaturesFromDrawing = ({ features }) => {
    if (!map) {
      return;
    }
    const selectionPoly = features[0];

    const polygonBoundingBox = bbox(selectionPoly);

    const southWest = map.project([
      polygonBoundingBox[0],
      polygonBoundingBox[1]
    ]);
    const northEast = map.project([
      polygonBoundingBox[2],
      polygonBoundingBox[3]
    ]);

    const bbox_features = map.queryRenderedFeatures([southWest, northEast], {
      layers: [
        "postcode-unit-boundaries",
        "sector-plus-one-boundaries",
        "postcode-sector-boundaries",
        "postcode-district-boundaries"
      ]
    });
    console.log(draw.getSelected());
    const selectedFeatures = [];
    bbox_features.reduce(function (bbox_features, feature) {
      if (
        (feature.geometry.type === "Polygon" ||
          feature.geometry.type === "Linestring") &&
        (booleanOverlap(feature, selectionPoly) ||
          booleanContains(selectionPoly, feature))
      ) {
        selectedFeatures.push(feature);
      }
    });
    console.log(selectedFeatures);
    selectedFeatures.forEach(f => {
      map.setFeatureState(
        {
          source: f.source,
          sourceLayer: f.sourceLayer,
          id: f.id
        },
        {
          selected: true
        }
      );
    });
    const postcodes = selectedFeatures.map(f => f.state.unit_code);
    alert(postcodes);
  };

  return (
    <>
      <DrawControl
        ref={drawControl => {
          draw = drawControl?.draw;
        }}
        onDrawCreate={getFeaturesFromDrawing}
      />
    </>
  );
};
