import {
  CheckboxWithLabel,
  Select
} from "@dpdgroupuk/central-collections-common";
import { useDispatch, useSelector } from "react-redux";

import React from "react";
import { SketchPicker } from "react-color";
import { setHighlightSimilar } from "../../../store/reducers/matrixTemplateReducer";

export const TemplateSideBarDeckGl = () => {
  const { highlightSimilar } = useSelector(
    state => state.matrixTemplateReducer
  );

  const dispatch = useDispatch();
  console.log(highlightSimilar);
  return (
    <>
      <h4>Template Options</h4>
      <CheckboxWithLabel
        check={highlightSimilar.enabled}
        label={"Highlight similar"}
        onChange={() =>
          dispatch(setHighlightSimilar({ enabled: !highlightSimilar.enabled }))
        }
      />
      {highlightSimilar.enabled && (
        <>
          <Select
            name={"similar_type"}
            defaultValue={highlightSimilar.type}
            onChange={e =>
              dispatch(setHighlightSimilar({ type: e.target.value }))
            }
            options={[
              "delivery_route",
              "smalls_route",
              "bulk_route",
              "collection_route"
            ]}
          />
          <SketchPicker
            color={highlightSimilar.colour}
            onChangeComplete={e =>
              dispatch(setHighlightSimilar({ colour: e.hex, rgb: e.rgb }))
            }
          />
        </>
      )}
    </>
  );
};
