import { applyMiddleware, combineReducers, compose, createStore } from "redux";

import boundariesReducer from "./reducers/boundariesReducer";
import collectionsReducer from "./reducers/collectionsReducer";
import deckglLayersReducer from "./reducers/deckglLayersReducer";
import layersReducer from "./reducers/layersReducer";
import mapReducer from "./reducers/mapReducer";
import matrixTemplateReducer from "./reducers/matrixTemplateReducer";
import multiMapReducer from "./reducers/multiMapReducer";
import postcodeAllocationReducer from "./reducers/postcodeAllocationReducer";
import sideBarReducer from "./reducers/sideBarReducer";
import sourcesReducer from "./reducers/sourcesReducer";
import thunk from "redux-thunk";
import zoomReducer from "./reducers/zoomReducer";

const reducers = combineReducers({
  mapReducer,
  sideBarReducer,
  layersReducer,
  matrixTemplateReducer,
  zoomReducer,
  postcodeAllocationReducer,
  deckglLayersReducer,
  collectionsReducer,
  boundariesReducer,
  multiMapReducer,
  sourcesReducer
});

export default () => {
  return createStore(
    reducers,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
  );
};
