import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allowOverlap: true,
  deliveryRoutes: {
    enabled: true,
    waves: {
      0: { name: "Wave 1", enabled: true, wave: 0 },
      1: { name: "Wave 2", enabled: true, wave: 1 },
      2: { name: "Wave 3", enabled: true, wave: 2 },
      3: { name: "Wave 4", enabled: true, wave: 3 }
    },
    routes: null
  }
};

const multiMapReducer = createSlice({
  name: "multiMap",
  initialState,
  reducers: {
    toggleAllowOverlap: state => {
      state.allowOverlap = !state.allowOverlap;
    },
    setDeliveryRoutesMultiMap: (state, action) => {
      state.deliveryRoutes = { ...state.deliveryRoutes, ...action.payload };
    }
  }
});

export const {
  toggleAllowOverlap,
  setDeliveryRoutesMultiMap
} = multiMapReducer.actions;
export default multiMapReducer.reducer;
