import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  onHover: false,
  depotFilter: {
    enabled: false,
    depot: ""
  },
  highlightSimilar: {
    enabled: false,
    type: "delivery_route",
    colour: "#ff0000",
    hex: { r: 255, g: 0, b: 0, a: 1 }
  },
  bulkRoutes: {
    enabled: false,
    routes: null
  },
  deliveryRoutes: {
    enabled: false,
    waves: {
      0: { name: "Wave 1", enabled: false, opacity: 0.5 },
      1: { name: "Wave 2", enabled: false, opacity: 0.5 },
      2: { name: "Wave 3", enabled: false, opacity: 0.5 },
      3: { name: "Wave 4", enabled: false, opacity: 0.5 }
    },
    routes: null
  }
};

const boundariesReducer = createSlice({
  name: "boundaries",
  initialState,
  reducers: {
    toggleOnHover: state => {
      state.onHover = !state.onHover;
    },
    setDepotFilter: (state, action) => {
      state.depotFilter = { ...state.depotFilter, ...action.payload };
    },
    setHighlightSimilar: (state, action) => {
      state.highlightSimilar = { ...state.highlightSimilar, ...action.payload };
    },
    setBulkRoutes: (state, action) => {
      state.bulkRoutes = { ...state.bulkRoutes, ...action.payload };
    },
    setDeliveryRoutes: (state, action) => {
      state.deliveryRoutes = { ...state.deliveryRoutes, ...action.payload };
    }
  }
});

export const {
  toggleOnHover,
  setDepotFilter,
  setHighlightSimilar,
  setBulkRoutes,
  setDeliveryRoutes
} = boundariesReducer.actions;
export default boundariesReducer.reducer;
