import { depotData } from "../../constants/constants";
import { useDispatch } from "react-redux";
import {
  actionSetRoutes,
  setCurrentState,
  getRouteDetails,
  getDepots,
  getDepotRoutes,
  actionSetDepotInfo
} from "../../actions/index";
import { GENERAL_VIEW, DEPOT_VIEW, ROUTE_VIEW } from "../../actions/constants";
import { setInfoData } from "../../actions/sideBarActions";
import GeoJSON from "geojson";

export default class DepotViewController {
  // private fields
  #data;
  #dispatch;

  constructor() {
    this.#data = depotData;
    this.#dispatch = useDispatch();
  }

  // Getters
  data() {
    return this.#data;
  }

  //TO DO change according to database data
  clickTree = nodes => {
    if (nodes.id === "root") {
      return this.#dispatch(setCurrentState({ view: GENERAL_VIEW }));
    }

    if (nodes.id.indexOf("depot") > -1) {
      return this.#dispatch(setCurrentState({ view: DEPOT_VIEW }));
    }

    this.#dispatch(setCurrentState({ view: ROUTE_VIEW }));
  };

  onClickDepot = depot => {
    if (depot.container) {
      return;
    }
    this.#dispatch(actionSetRoutes(depot.children));
    switch (depot.type) {
      case "route":
        this.#dispatch(getRouteDetails(depot));
        return this.#dispatch(
          setInfoData(
            GeoJSON.parse(depot, {
              Point: ["latitude", "longitude"],
              extra: depot
            })
          )
        );
      case "root":
        return this.#dispatch(getDepots());
      case "depot":
        return this.#dispatch(getDepotRoutes(depot));
      default:
        return this.#dispatch(
          actionSetDepotInfo(
            !isNaN(depot.longitude) &&
              !isNaN(depot.latitude) && [depot.longitude, depot.latitude]
          )
        );
    }
  };
}
