import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { List, ListItem, ListItemText } from "@material-ui/core";

export const StopsList = ({ stops, onDragEnd }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <List {...provided.droppableProps} innerRef={provided.innerRef}>
            {stops.length &&
              stops.map((stop, index) => (
                <Draggable key={stop.id} draggableId={stop.id} index={index}>
                  {provided => (
                    <ListItem
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      innerRef={provided.innerRef}
                      divider
                    >
                      <ListItemText>{stop.name}</ListItemText>
                    </ListItem>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};
