import {
  CHANGE_TAB,
  SET_INFO_DATA,
  SET_TREE_DATA,
  TOGGLE_SIDE_BAR
} from "../../actions/constants";

const initialState = {
  infoData: undefined,
  treeData: {},
  isOpen: false,
  selectedTab: 0
};

export default function mapReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TREE_DATA:
      return { ...state, treeData: action.payload };
    case SET_INFO_DATA:
      return { ...state, infoData: action.payload };
    case TOGGLE_SIDE_BAR:
      return { ...state, isOpen: !state.isOpen };
    case CHANGE_TAB:
      return { ...state, selectedTab: action.payload };
    default:
      return state;
  }
}
