export const findMatrixLine = (properties, matrixTemplate) => {
  const original = { ...properties };
  if (!properties) {
    return;
  }
  let { line, currentProperties } = searchMatrixLine(
    properties,
    matrixTemplate
  );
  if (
    line &&
    original.name !== currentProperties.name &&
    original.level !== currentProperties.level
  ) {
    console.log(original, currentProperties);
    line.inherited = true;
  }
  return line;
};

const searchMatrixLine = (properties, matrixTemplate) => {
  console.log(properties);
  const found = matrixTemplate.find(t => {
    if (properties.level === 0) {
      return t.outer === properties.name && t.sector === "";
    } else if (properties.level === 1) {
      return t.sector === properties.name && t.sector_plus1 === "";
    } else if (properties.level === 2) {
      return t.sector_plus1 === properties.name && t.postcode === "";
    } else {
      return t.postcode === properties.name;
    }
  });
  if (!found && properties.level > 0) {
    return searchMatrixLine(
      {
        level: properties.level - 1,
        name: properties.name.substring(0, properties.name.length - 1)
      },
      matrixTemplate
    );
  }
  return { line: { ...found }, currentProperties: properties };
};

export const getZoomLevel = zoom => {
  // if (zoom < 8) {
  //   return 0;
  // } else
  if (zoom < 10) {
    return 1;
  } else if (zoom < 14) {
    return 2;
  } else {
    return 3;
  }
};
