import { Layer, Source } from "react-mapbox-gl";
import {
  POSTCODE_LEVEL_MATRIX_ID,
  setSourceLoaded
} from "../../store/reducers/sourcesReducer";
import React, { useEffect, useState } from "react";
import { isEqual, memoize } from "lodash";
import { setMapCenter, zoomChanged } from "../../store/reducers/zoomReducer";
import {
  setMatrixTemplate,
  setSidePanel
} from "../../store/reducers/matrixTemplateReducer";
import { useDispatch, useSelector } from "react-redux";

import { TemplatePopUp } from "./TemplatePopUp";
import { useSnackbar } from "notistack";

export const PostcodeLevelMatrix = ({ map }) => {
  const {
    postcodeGeoJson,
    matrixTemplate,
    onHover,
    onClick,
    searchInput,
    mapping,
    highlightSimilar,
    allowOverlap,
    allocation,
    sidePanel
  } = useSelector(state => state.matrixTemplateReducer);
  const { enqueueSnackbar } = useSnackbar();
  const zoom = useSelector(state => state.zoomReducer.zoom);
  const postcodeAllocation = useSelector(
    state => state.postcodeAllocationReducer.postcodeAllocation
  );
  const { bulkRoutes, deliveryRoutes } = useSelector(
    state => state.boundariesReducer
  );
  const [labelsLoaded, setLabelsLoaded] = useState(false);
  const [feature, setFeature] = useState();
  const [selected, setSelected] = useState({ properties: { name: "DN6 0" } });
  const [id, setId] = useState();
  const [matching, setMatching] = useState();
  const [routesGeoJson, setRoutesGeoJson] = useState();
  const [waveSelected, setWaveSelected] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const waves = [];
    Object.keys(deliveryRoutes.waves).forEach(key => {
      if (deliveryRoutes.waves[key].enabled) {
        waves.push(key);
      }
    });
    setWaveSelected(waves.length > 0 ? Math.max(...waves) : null);
  }, [deliveryRoutes.waves]);

  useEffect(() => {
    if (!map || !bulkRoutes.labels) {
      return;
    }
    const newGeoJson = [];
    Object.keys(bulkRoutes.labels).forEach(id => {
      if (!postcodeGeoJson.features[id]) {
        return;
      }
      newGeoJson.push({
        ...postcodeGeoJson.features[id],
        properties: { bulkRoute: bulkRoutes.labels[id] }
      });
    });

    setRoutesGeoJson({ type: "FeatureCollection", features: newGeoJson });
  }, [bulkRoutes.labels, map]);

  useEffect(() => {
    if (!map || !deliveryRoutes.labels) {
      return;
    }
    const newGeoJson = [];
    Object.keys(deliveryRoutes.labels).forEach(id => {
      if (!postcodeGeoJson.features[id]) {
        return;
      }
      newGeoJson.push({
        ...postcodeGeoJson.features[id],
        properties: { ...deliveryRoutes.labels[id] }
      });
    });

    setRoutesGeoJson({ type: "FeatureCollection", features: newGeoJson });
  }, [deliveryRoutes.labels, map]);

  const getZoomLevel = () => {
    if (zoom < 10) {
      return 0;
    } else if (zoom < 13) {
      return 1;
    } else if (zoom < 15) {
      return 2;
    } else {
      return 2;
    }
  };

  const getZoomBasedOnLevel = level => {
    if (level === 0) {
      return 8;
    } else if (level === 1) {
      return 12;
    } else if (level === 2) {
      return 14;
    } else {
      return 14;
    }
  };

  function getLabelsColors() {
    const array = Object.values(postcodeAllocation).map(pa => [
      pa.depotCode,
      pa.color
    ]);
    return [].concat(...array);
  }

  const labelsColors = memoize(getLabelsColors);

  const findMatrixLine = properties => {
    const original = { ...properties };
    if (!properties) {
      return;
    }
    let { line, currentProperties } = searchMatrixLine(properties);
    if (
      line &&
      original.name !== currentProperties.name &&
      original.level !== currentProperties.level
    ) {
      line.inherited = true;
    }
    return line;
  };

  const searchMatrixLine = properties => {
    const found = matrixTemplate.find(t => {
      if (properties.level === 0) {
        return t.outer === properties.name && t.sector === "";
      } else if (properties.level === 1) {
        return t.sector === properties.name && t.sector_plus1 === "";
      } else if (properties.level === 2) {
        return t.sector_plus1 === properties.name && t.postcode === "";
      } else {
        return t.postcode === properties.name;
      }
    });
    if (!found && properties.level > 0) {
      return searchMatrixLine({
        level: properties.level - 1,
        name: properties.name.substring(0, properties.name.length - 1)
      });
    }
    return { line: { ...found }, currentProperties: properties };
  };

  const getFeaturesBasedOnDeliveryRoute = id => {
    const postcode = mapping.featureToPostcode[id].toString();
    const level = getZoomLevel();
    const line = matrixTemplate.filter(m => {
      if (level === 0) {
        return m.outer === postcode && m.sector === "";
      } else if (level === 1) {
        return m.sector === postcode && m.sector_plus1 === "";
      } else if (level === 2) {
        return m.sector_plus1 === postcode && m.postcode === "";
      } else {
        return m.postcode === postcode;
      }
    });

    if (!line[0]) {
      return [];
    }

    const matchingPostcodes = matrixTemplate.filter(
      m =>
        isEqual(m[highlightSimilar.type], line[0][highlightSimilar.type]) &&
        ((level === 0 && m.sector === "" && m.outer !== line[0].outer) ||
          (level === 1 &&
            m.sector_plus1 === "" &&
            m.sector !== "" &&
            m.sector !== line[0].sector) ||
          (level === 2 &&
            m.postcode === "" &&
            m.sector_plus1 !== "" &&
            m.sector_plus1 !== line[0].sector_plus1) ||
          (level === 3 && m.postcode !== line[0].postcode))
    );

    const featureIds = matchingPostcodes.map(m => {
      if (level === 0) {
        return mapping.postcodeToFeature[m.outer];
      } else if (level === 1) {
        return mapping.postcodeToFeature[m.sector];
      } else if (level === 2) {
        return mapping.postcodeToFeature[m.sector_plus1];
      } else {
        return mapping.postcodeToFeature[m.postcode];
      }
    });
    setMatching(featureIds);

    return featureIds;
  };

  const getLevelNameFromLevel = level => {
    if (level === 0) {
      return "outer";
    } else if (level === 1) {
      return "sector";
    } else {
      return "sector_plus1";
    }
  };

  const allocateRoute = ({ featureId, properties }) => {
    if (!featureId) {
      enqueueSnackbar("Could not get the postcode", { variant: "error" });
      return;
    }
    const line = findMatrixLine(properties);
    const newLine = { ...line, [allocation.type]: allocation.selectedRoute };

    const level = getZoomLevel();
    if (line.inherited) {
      delete newLine.inherited;
      const myLine = {
        ...newLine,
        [getLevelNameFromLevel(level)]: properties.name
      };
      dispatch(setMatrixTemplate([...matrixTemplate, myLine]));
      enqueueSnackbar(
        `${allocation.type.split("_").join(" ")} ${
          allocation.selectedRoute
        } allocated to postcode ${getPostcodeForLevel(myLine, level)}`,
        { variant: "success" }
      );
    } else {
      const index = matrixTemplate.findIndex(m => {
        return (
          (level === 0 && m.sector === "" && m.outer === line.outer) ||
          (level === 1 &&
            m.sector_plus1 === "" &&
            m.sector !== "" &&
            m.sector === line.sector) ||
          (level === 2 &&
            m.postcode === "" &&
            m.sector_plus1 !== "" &&
            m.sector_plus1 === line.sector_plus1) ||
          (level === 3 && m.postcode === line.postcode)
        );
      });
      const newMatrix = [...matrixTemplate];
      newMatrix.splice(index, 1, newLine);
      dispatch(setMatrixTemplate(newMatrix));
      enqueueSnackbar(
        `${allocation.type.split("_").join(" ")} ${
          allocation.selectedRoute
        } allocated to postcode ${getPostcodeForLevel(newLine, level)}`,
        { variant: "success" }
      );
    }
  };

  const getPostcodeForLevel = (line, level) => {
    if (level === 0) {
      return line.outer;
    } else if (level === 1) {
      return line.sector;
    } else if (level === 2) {
      return line.sector_plus1;
    } else {
      return line.postcode === "" ? line.sector_plus1 : line.postcode;
    }
  };

  return (
    <>
      {feature && (
        <TemplatePopUp
          coordinates={[feature.lngLat.lng, feature.lngLat.lat]}
          properties={feature.properties}
        />
      )}

      <Source
        id={POSTCODE_LEVEL_MATRIX_ID}
        geoJsonSource={{ data: postcodeGeoJson, type: "geojson" }}
        onSourceLoaded={source => dispatch(setSourceLoaded(source.id))}
      />
      <Layer
        id={"template-layer"}
        type={"symbol"}
        sourceId={POSTCODE_LEVEL_MATRIX_ID}
        layout={{
          "text-field": ["get", "name"],
          "text-allow-overlap": allowOverlap,
          "text-size": 11,
          "text-anchor": "bottom"
        }}
        filter={
          searchInput !== ""
            ? ["in", searchInput.toLowerCase(), ["downcase", ["get", "name"]]]
            : ["==", "level", getZoomLevel()]
        }
        paint={{
          "text-color": [
            "match",
            ["feature-state", "hover"],
            "true",
            "#00b300",
            "#000"
          ]
        }}
        onClick={e => {
          setFeature(null);
          if (sidePanel.open) {
            dispatch(setSidePanel({ selectedFeature: e.features[0] }));
          }
          if (allocation.enabled) {
            console.log("allocation");
            allocateRoute({
              featureId: e.features[0]?.id,
              properties: e.features[0]?.properties
            });
          }
          if (!onClick) {
            return;
          }
          setSelected(e.features[0]);
          dispatch(
            zoomChanged(getZoomBasedOnLevel(e.features[0].properties.level))
          );
          dispatch(setMapCenter(e.lngLat));
        }}
        onMouseEnter={e => {
          setFeature(null);
          if (id) {
            map.setFeatureState({ source: "template", id }, { hover: "false" });
          }
          if (matching) {
            matching.forEach(id => {
              if (!id) {
                return;
              }
              map.setFeatureState(
                { source: "template", id },
                { matching: "false" }
              );
            });
          }

          map.setFeatureState(
            { source: "template", id: e.features[0].id },
            { hover: "true" }
          );
          setId(e.features[0].id);

          if (highlightSimilar.enabled) {
            const matchingIds = getFeaturesBasedOnDeliveryRoute(
              e.features[0].id
            );
            matchingIds.forEach(id => {
              if (!id) {
                return;
              }
              map.setFeatureState(
                { source: "template", id },
                { matching: "true" }
              );
            });
          }
          if (!onHover) {
            return;
          }
          const properties = e.features[0]?.properties;
          if (properties.level === 0) {
            return;
          }
          const find = findMatrixLine(properties);
          if (find) {
            setFeature({ properties: find, lngLat: e.lngLat });
          }
        }}
        onMouseLeave={e => {
          setFeature(null);
          map.setFeatureState({ source: "template", id }, { hover: "false" });
          if (matching) {
            matching.forEach(id => {
              if (!id) {
                return;
              }
              map.setFeatureState(
                { source: "template", id },
                { matching: "false" }
              );
            });
          }
        }}
        onMouseDown={e => console.log("postcode", e)}
      />

      {routesGeoJson && (
        <Source
          id={"routes"}
          geoJsonSource={{ data: routesGeoJson, type: "geojson" }}
          onSourceLoaded={() => setLabelsLoaded(true)}
        />
      )}
      {bulkRoutes.enabled && labelsLoaded && (
        <Layer
          id={"routes-layer"}
          type={"symbol"}
          sourceId={"routes"}
          layout={{
            "text-field": ["get", "bulkRoute"],
            "text-anchor": "top",
            "text-size": 11,
            "text-allow-overlap": allowOverlap
          }}
        />
      )}
      {deliveryRoutes.enabled && labelsLoaded && (
        <Layer
          id={"routes-layer"}
          type={"symbol"}
          sourceId={"routes"}
          layout={{
            "text-field": [
              "get",
              !isNaN(waveSelected) ? `delivery_${waveSelected}_route` : ""
            ],
            "text-anchor": "top",
            "text-size": 11,
            "text-allow-overlap": allowOverlap
          }}
        />
      )}
    </>
  );
};
