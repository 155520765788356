import DeckGL, {
  ArcLayer,
  GeoJsonLayer,
  H3HexagonLayer,
  TextLayer
} from "deck.gl";
import React, { useEffect, useState } from "react";
import { findMatrixLine, getZoomLevel } from "../MatrixTemplate/utils";
import { useDispatch, useSelector } from "react-redux";

import { RenderLayers } from "./MatrixTemplate/RenderLayers";
import { StaticMap } from "react-map-gl";
import { TemplatePopUpDeckGl } from "./MatrixTemplate/TemplatePopUpDeckGl";
import { getPostcodeWithTheSameRoutes } from "./MatrixTemplate/utils";
import { zoomChanged } from "../../store/reducers/zoomReducer";

const h3 = require("h3-js");
const h3Index = h3.geoToH3(52.476282, -1.904023, 3);
const hexBoundary = h3.h3ToGeoBoundary(h3Index);
const h3set = h3.polyfill(hexBoundary, 5);

//let h3set = ["85195c07fffffff", "87195c04bffffff","87195c044ffffff","87195c05dffffff","87195c056ffffff","87195c068ffffff","87195c048ffffff","87195c061ffffff","87195c041ffffff","87195c05affffff","87195c073ffffff","87195c053ffffff","87195c06cffffff","87195c04cffffff","87195c065ffffff","87195c045ffffff","87195c05effffff","87195c070ffffff","87195c050ffffff","87195c069ffffff","87195c049ffffff","87195c062ffffff","87195c0edffffff","87195c042ffffff","87195c05bffffff","87195c074ffffff","87195c054ffffff","87195c3a4ffffff","87195c04dffffff","87195c066ffffff","87195c046ffffff","87195c058ffffff","87195c071ffffff","87195c336ffffff","87195c051ffffff","87195c06affffff","87195c04affffff","87195c063ffffff","87195c043ffffff","87195c05cffffff","87195c075ffffff","87195c055ffffff","87195c06effffff","87195c04effffff","87195c060ffffff","87195c040ffffff","87195c059ffffff","87195c072ffffff","87195c052ffffff","87195c06bffffff"];

const data = h3set.map(hex => {
  return {
    hex,
    count: Math.random() * 1000
  };
});

// source: Natural Earth http://www.naturalearthdata.com/ via geojson.xyz
const COUNTRIES =
  "https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_scale_rank.geojson"; //eslint-disable-line
const AIR_PORTS =
  "https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_airports.geojson";

const INITIAL_VIEW_STATE = {
  latitude: 51.47,
  longitude: 0.45,
  zoom: 4,
  bearing: 0,
  pitch: 30
};

function NewMapComponent() {
  const deckglLayersReducer = useSelector(state => state.deckglLayersReducer);
  const {
    postcodeGeoJson,
    postcodeLabels,
    matrixTemplate,
    highlightSimilar
  } = useSelector(state => state.matrixTemplateReducer);
  const [level, setLevel] = useState(-1);
  const [labelsData, setLabelsData] = useState([]);
  const [layers, setLayers] = useState([]);
  const [hoverInfo, setHoverInfo] = useState();
  const [matchingPostcodes, setMatchingPostcodes] = useState([]);
  const [mapping, setMapping] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const myMapping = {};
    Object.values(postcodeLabels).forEach((labels, index) => {
      const postcodeToIndex = {};
      labels.forEach((l, i) => {
        postcodeToIndex[l.properties.name] = i;
      });
      myMapping[index] = postcodeToIndex;
    });
    setMapping(myMapping);
  }, [postcodeLabels]);

  console.log(mapping);

  useEffect(() => {
    if (!hoverInfo || !hoverInfo.object || !hoverInfo.object.properties) {
      return;
    }
    setMatchingPostcodes(
      getPostcodeWithTheSameRoutes({
        postcode: hoverInfo.object.properties.name,
        level,
        matrixTemplate,
        type: highlightSimilar.type
      })
    );
  }, [hoverInfo]);

  const getMatrixFromHoverInfo = () => {
    if (!hoverInfo.object) {
      return;
    }
    return findMatrixLine(hoverInfo.object.properties, matrixTemplate);
  };

  const getColor = item => {
    console.log(item.properties.name);
    return hoverInfo && matchingPostcodes.includes(item.properties.name)
      ? [
          highlightSimilar.r,
          highlightSimilar.g,
          highlightSimilar.b,
          highlightSimilar.a
        ]
      : [0, 0, 0, 255];
  };

  useEffect(() => {
    console.log("setting new labels", level);
    if (postcodeLabels[level]) {
      setLabelsData(postcodeLabels[level]);
    }
  }, [level]);

  useEffect(() => {
    if (!labelsData) {
      return;
    }

    setLayers(
      RenderLayers({
        showLabels: deckglLayersReducer.postcodeLabelsLayer.display,
        data: labelsData,
        onHover: info => {
          console.log(info);
          setHoverInfo(info);
        }
        // getColor: getColor,
        // updateTriggers: {
        //   getColor: [matchingPostcodes, hoverInfo]
        // }
      })
    );
  }, [labelsData]);

  const onClick = info => {
    if (info.object) {
      // eslint-disable-next-line
      alert(
        `${info.object.properties.name} (${info.object.properties.abbrev})`
      );
    }
  };

  return (
    <DeckGL
      controller={true}
      initialViewState={INITIAL_VIEW_STATE}
      onViewStateChange={({ viewState }) => {
        const newLevel = getZoomLevel(viewState.zoom);
        if (newLevel !== level) {
          setLevel(newLevel);
        }
      }}
      layers={layers}
    >
      <StaticMap key="map" mapStyle={"mapbox://styles/mapbox/light-v10"} />

      {deckglLayersReducer.mapLayer.display && (
        <GeoJsonLayer
          id="base-map"
          data={COUNTRIES}
          stroked={true}
          filled={true}
          lineWidthMinPixels={2}
          opacity={0.4}
          getLineColor={[60, 60, 60]}
          getFillColor={[200, 200, 200]}
        />
      )}

      {deckglLayersReducer.arcsLayer.display && (
        <>
          <GeoJsonLayer
            id="airports"
            data={AIR_PORTS}
            filled={true}
            pointRadiusMinPixels={2}
            pointRadiusScale={2000}
            getRadius={f => 11 - f.properties.scalerank}
            getFillColor={[200, 0, 80, 180]}
            pickable={true}
            autoHighlight={true}
            onClick={onClick}
          />
          <ArcLayer
            id="arcs"
            data={AIR_PORTS}
            dataTransform={d =>
              d.features.filter(f => f.properties.scalerank < 4)
            }
            getSourcePosition={f => [-0.4531566, 51.4709959]}
            getTargetPosition={f => f.geometry.coordinates}
            getSourceColor={[0, 128, 200]}
            getTargetColor={[200, 0, 80]}
            getWidth={1}
          />
        </>
      )}

      {deckglLayersReducer.h3HexagonLayer.display && (
        <H3HexagonLayer
          id={"h3-hexagon-layer"}
          data={data}
          pickable={true}
          wireframe={false}
          filled={true}
          extruded={true}
          elevationScale={20}
          getHexagon={d => d.hex}
          getFillColor={d => [255, (1 - d.count / 500) * 255, 0]}
          getElevation={d => d.count}
        />
      )}

      {hoverInfo && hoverInfo.object && (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            pointerEvents: "none",
            left: hoverInfo.x,
            top: hoverInfo.y
          }}
        >
          <TemplatePopUpDeckGl properties={getMatrixFromHoverInfo()} />
          {/* {hoverInfo.object.properties.name} */}
        </div>
      )}
    </DeckGL>
  );
}

export default NewMapComponent;
