export const fillPattern = (backgroundColor, lineColor) => {
  return `<svg xmlns="http://www.w3.org/2000/svg" height="211.96" width="211.96"><g fill="#ffa500"><path d="M174.093 50.976L68.112 156.957 13.108 211.96h11.473l30.336-30.336L160.902 75.643l51.063-51.063V13.107l-37.87 37.869M54.919 75.643L130.565-.004H119.09l-50.979 50.98L0 119.086v11.474l54.918-54.917M40.524 61.25L101.777-.005h-4.24L57.335 40.2h-.001L0 97.535v4.238l40.523-40.524h.001M66.363 86.42L152.785-.005h-4.238L83.173 65.369 0 148.542v4.24l66.362-66.363M33.723 42.901L76.628-.004h-8.834L48.237 19.553 0 67.79v8.835l33.723-33.723M13.109-.004L0 13.106v11.473L24.582-.004H13.109M42.565-.004L0 42.56v4.24L46.804-.004h-4.24M40.524 167.23L146.507 61.25 207.76-.005h-4.24L163.316 40.2 57.335 146.181h-.001L0 203.516v4.238l40.523-40.523h.001M83.173 171.352L42.564 211.96h4.239l19.558-19.558L172.346 86.419l39.619-39.618v-4.238l-22.81 22.806-105.98 105.983M33.723 148.883L139.705 42.901 182.611-.004h-8.835l-19.557 19.557L48.237 125.535 0 173.771v8.835l33.723-33.723M207.76 211.96l4.204-4.204v-4.24l-8.444 8.444h4.24M182.61 211.96l29.354-29.353v-8.833l-38.188 38.186h8.835M119.091 211.96h11.474l30.336-30.336 51.063-51.062v-11.473l-37.87 37.868-55.003 55.003M97.537 211.96h4.24l44.73-44.73 65.457-65.456v-4.24l-48.648 48.647-65.779 65.779M148.547 211.96h4.238l19.56-19.558 39.619-39.62v-4.237l-22.81 22.807-40.607 40.608M67.794 211.96h8.834l63.077-63.077 72.259-72.258v-8.833l-57.745 57.743-86.425 86.425"/></g></svg>`;
};

export const svgPathToImage = ({ path, width, height }) =>
  new Promise(resolve => {
    const image = new Image(width, height);
    image.addEventListener("load", () => resolve(image));
    image.src = path;
    return image;
  });

// This does a lookup of the symbol's name to find its string value
export const symbolAsInlineImage = ({ name, width, height }) =>
  svgPathToImage({
    path: "data:image/svg+xml;charset=utf-8;base64," + btoa(name),
    width,
    height
  });
