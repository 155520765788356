import { GeoJSONLayer, Layer, Source } from "react-mapbox-gl";
import React, { useEffect, useState } from "react";

import { CollectionsPopUp } from "./CollectionsPopUp";
import GeoJSON from "geojson";
import { isEqual } from "lodash";
import pointsWithinPolygons from "@turf/points-within-polygon";
import { useSelector } from "react-redux";

const h3 = require("h3-js");

const UK_BBOX = [
  [-10.372467, 49.840972],
  [-10.372467, 59.469762],
  [2.020111, 59.469762],
  [2.020111, 49.840972]
];
const bbox = [
  [-3.4236969843, 51.6887906982],
  [-2.403947259, 51.6887906982],
  [-2.403947259, 52.0217673703],
  [-3.4236969843, 52.0217673703],
  [-3.4236969843, 51.6887906982]
];

export const Collections = () => {
  const [points, setPoints] = useState();
  const { collections, hexLevel, scale } = useSelector(
    state => state.collectionsReducer
  );
  const [feature, setFeature] = useState();

  useEffect(() => {
    if (!collections) {
      return;
    }
    const hexes = {};
    collections.map(c => {
      const h = h3.geoToH3(c.latitude, c.longitude, hexLevel);
      if (hexes[h]) {
        hexes[h].properties.count = hexes[h].properties.count + scale;
      } else {
        hexes[h] = {
          properties: {
            count: scale
          },
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: h3.h3SetToMultiPolygon(
              h3.polyfill(h3.h3ToGeoBoundary(h), hexLevel),
              true
            )[0]
          }
        };
      }
    });
    const polygons = Object.values(hexes);

    setPoints({ type: "FeatureCollection", features: polygons });
  }, [collections, hexLevel, scale]);

  return (
    <>
      {feature && (
        <CollectionsPopUp
          coordinates={[feature.lngLat.lng, feature.lngLat.lat]}
          properties={feature.properties}
          countOffset={scale}
        />
      )}
      {points && (
        <>
          <Source
            id={"collections"}
            geoJsonSource={{ data: points, type: "geojson" }}
          />
          <Layer
            id={"collections-layer"}
            type={"fill-extrusion"}
            sourceId={"collections"}
            paint={{
              "fill-extrusion-color": [
                "interpolate",
                ["linear"],
                ["/", ["get", "count"], scale],
                1,
                "rgba(0, 0, 255, 0)",
                4,
                "royalblue",
                10,
                "cyan",
                15,
                "lime",
                20,
                "yellow",
                30,
                "red"
              ],
              "fill-extrusion-height": ["get", "count"],
              "fill-extrusion-opacity": 0.6
            }}
            onMouseEnter={e => {
              setFeature({
                properties: e.features[0]?.properties,
                lngLat: e.lngLat
              });
            }}
            onMouseMove={e => {
              setFeature({
                properties: e.features[0]?.properties,
                lngLat: e.lngLat
              });
            }}
            onMouseLeave={() => {
              setFeature(null);
            }}
            onMouseClick={() => setFeature(null)}
          />
        </>
      )}
    </>
  );
};
