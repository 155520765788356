import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { depotData, drawerWidth } from "./constants/constants";
import {
  setMapping,
  setMatrixTemplate,
  setPostcodeGeoJson
} from "./store/reducers/matrixTemplateReducer";
import { setTreeData, toggleSideBar } from "./actions/sideBarActions";
import { useDispatch, useSelector } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";
import MapComponent from "./components/MapComponent";
import { MultiMap } from "./components/MultiMaps/MultiMap";
import NewMapComponent from "./components/NewMapComponent/NewMapComponent";
import SideBar from "./components/SideBar";
import { SidePanel } from "./components/MatrixTemplate/SidePanel";
import { SnackbarProvider } from "notistack";
import TopBar from "./components/TopBar";
import clsx from "clsx";
import { getDepots } from "./actions";
import { makeStyles } from "@material-ui/core/styles";
import { setCollections } from "./store/reducers/collectionsReducer";
import { setPostcodeAllocation } from "./store/reducers/postcodeAllocationReducer";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%"
  },
  content: {
    flexGrow: 1,
    height: "calc(100vh - 64px)",
    marginTop: "64px ",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 0
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: drawerWidth
  }
}));

function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.sideBarReducer.isOpen);
  const selectedTab = useSelector(state => state.sideBarReducer.selectedTab);

  const handleDrawerOpen = () => {
    !isOpen && dispatch(toggleSideBar());
  };

  const handleDrawerClose = () => {
    isOpen && dispatch(toggleSideBar());
  };

  useEffect(() => {
    dispatch(setTreeData(depotData));
    dispatch(getDepots());
  }, [dispatch]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_MATRIX_API}/depot/0030/test/labels`)
      .then(d => d.json())
      .then(d => {
        dispatch(setPostcodeGeoJson(d.labels));
        dispatch(setMapping(d.mapping));
      });

    fetch(
      `${process.env.REACT_APP_MATRIX_API}/depot/0030/partialPostcode/allocationsMapping`
    )
      .then(d => d.json())
      .then(d => dispatch(setPostcodeAllocation(d.data)));

    fetch(`${process.env.REACT_APP_MATRIX_API}/depot/0030/test/3`)
      .then(d => d.json())
      .then(d => dispatch(setMatrixTemplate(d.data)));

    fetch(`${process.env.REACT_APP_MATRIX_API}/depot/0030/test/collections`)
      .then(d => d.json())
      .then(d => dispatch(setCollections(d.rows)));
  }, []);

  const results = useSelector(state => ({
    currentState: state.mapReducer.currentState
  }));

  return (
    <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      <Router>
        <Switch>
          <Route path={`/`} exact>
            <div className={classes.root}>
              <CssBaseline />
              <SideBar open={isOpen} handleDrawerClose={handleDrawerClose} />

              <div>
                <TopBar
                  title={results.currentState.view}
                  handleDrawerOpen={handleDrawerOpen}
                  open={isOpen}
                />
              </div>

              <div
                className={clsx(classes.content, {
                  [classes.contentShift]: isOpen
                })}
              >
                {selectedTab === 0 && <MapComponent />}
                {selectedTab === 1 && <NewMapComponent />}
                {selectedTab === 2 && <MultiMap />}
              </div>
            </div>
          </Route>
          <Route path={`/new`}>
            <div className={classes.root}>
              <CssBaseline />
              <SideBar open={isOpen} handleDrawerClose={handleDrawerClose} />
              <div>
                <TopBar
                  title={results.currentState.view}
                  handleDrawerOpen={handleDrawerOpen}
                  open={isOpen}
                />
              </div>

              <div
                className={clsx(classes.content, {
                  [classes.contentShift]: isOpen
                })}
              >
                <NewMapComponent />
              </div>
            </div>
          </Route>
        </Switch>
      </Router>
    </SnackbarProvider>
  );
}

export default App;
