import {
  CheckboxWithLabel,
  Select,
  TextInput
} from "@dpdgroupuk/central-collections-common";
import { List, ListItem } from "@material-ui/core";
import {
  setAllocation,
  setHighlightSimilar,
  setSearchInput,
  setSidePanel,
  toggleAllowOverlap,
  toggleOnClick,
  toggleOnHover
} from "../../store/reducers/matrixTemplateReducer";
import { useDispatch, useSelector } from "react-redux";

import React from "react";
import { RouteAllocation } from "./RouteAllocation";
import { SketchPicker } from "react-color";

export const TemplateSideBar = () => {
  const {
    onHover,
    onClick,
    searchInput,
    highlightSimilar,
    allowOverlap,
    allocation,
    sidePanel
  } = useSelector(state => state.matrixTemplateReducer);
  const dispatch = useDispatch();

  return (
    <>
      <h4>Template Options</h4>
      <div>
        <p>Search by postcode</p>
        <TextInput
          value={searchInput}
          onChange={e => dispatch(setSearchInput(e.target.value))}
        />
      </div>{" "}
      <CheckboxWithLabel
        check={allowOverlap}
        label={"Allow overlap"}
        onChange={() => dispatch(toggleAllowOverlap())}
      />
      <CheckboxWithLabel
        check={onHover}
        label={"On Hover"}
        onChange={() => dispatch(toggleOnHover())}
      />
      <CheckboxWithLabel
        check={onClick}
        label={"On Click"}
        onChange={() => dispatch(toggleOnClick())}
      />
      <CheckboxWithLabel
        check={sidePanel.open}
        label={"Side Panel"}
        onChange={() => dispatch(setSidePanel({ open: !sidePanel.open }))}
      />
      <CheckboxWithLabel
        check={highlightSimilar.enabled}
        label={"Highlight similar"}
        onChange={() =>
          dispatch(setHighlightSimilar({ enabled: !highlightSimilar.enabled }))
        }
      />
      {highlightSimilar.enabled && (
        <>
          <Select
            name={"similar_type"}
            defaultValue={highlightSimilar.type}
            onChange={e =>
              dispatch(setHighlightSimilar({ type: e.target.value }))
            }
            options={[
              "delivery_route",
              "smalls_route",
              "bulk_route",
              "collection_route"
            ]}
          />
          <SketchPicker
            color={highlightSimilar.colour}
            onChangeComplete={e =>
              dispatch(setHighlightSimilar({ colour: e.hex }))
            }
          />
        </>
      )}
      <CheckboxWithLabel
        check={allocation.enabled}
        label={"Allocation"}
        onChange={() =>
          dispatch(setAllocation({ enabled: !allocation.enabled }))
        }
      />
      {allocation.enabled && <RouteAllocation />}
    </>
  );
};
