import {
  POSTCODE_DISTRICT_BOUNDARIES_ID,
  POSTCODE_LEVEL_MATRIX_ID,
  POSTCODE_SECTOR_BOUNDARIES_ID,
  POSTCODE_UNIT_BOUNDARIES_ID
} from "../../store/reducers/sourcesReducer";
import { fillPattern, symbolAsInlineImage } from "../../utils";
import { findMatrixLine, getZoomLevel } from "../MatrixTemplate/utils";
import { isEqual, memoize } from "lodash";

import { DeliveryRoutes } from "./DeliveryRoutes";
import { Layer } from "react-mapbox-gl";
import React from "react";
import { TemplatePopUp } from "../MatrixTemplate/TemplatePopUp";
import areaTable from "./lookupTables/boundaries-pos1-v3.json";
import districtTable from "./lookupTables/boundaries-pos2-v3.json";
import { getSectorPlusOne } from "./utils";
import { getSimilarFeatures } from "./utils";
import sectorPlusOneTable from "./lookupTables/boundaries-sectorplusone.json";
import sectorTable from "./lookupTables/boundaries-pos3-v3.json";
import unitTable from "./lookupTables/boundaries-pos4-v3.json";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";

const getTable = level => {
  if (level === 0) {
    return areaTable;
  } else if (level === 1) {
    return districtTable;
  } else if (level === 2) {
    return sectorTable;
  } else {
    return unitTable;
  }
};

const sourceMapping = {
  0: "postcode_area_boundaries",
  1: POSTCODE_DISTRICT_BOUNDARIES_ID,
  2: POSTCODE_SECTOR_BOUNDARIES_ID,
  3: POSTCODE_UNIT_BOUNDARIES_ID
};

const layerNames = [
  "postcode-area-boundaries",
  "postcode-district-boundaries",
  "postcode-sector-boundaries",
  "postcode-unit-boundaries"
];

export const Boundaries = ({ map }) => {
  const { zoom } = useSelector(state => state.zoomReducer);
  const { matrixTemplate } = useSelector(state => state.matrixTemplateReducer);
  const { matrixTemplateLayer } = useSelector(state => state.layersReducer);
  const {
    onHover,
    depotFilter,
    highlightSimilar,
    bulkRoutes,
    deliveryRoutes
  } = useSelector(state => state.boundariesReducer);
  const { postcodeMapping } = useSelector(
    state => state.postcodeAllocationReducer
  );
  const sources = useSelector(state => state.sourcesReducer);
  const [level, setLevel] = useState(0);
  const [hoverItem, setHoverItem] = useState();
  const [hoverId, setHoverId] = useState();
  const [hoverPoint, setHoverPoint] = useState();
  const [labelsColors, setLabelsColors] = useState([]);
  const [matchingIds, setMatchingIds] = useState([]);
  const [hoverSectorPlusOne, setHoverSectorPlusOne] = useState([]);
  const [postcodeHighlight, setPostcodeHighlight] = useState();

  useEffect(() => {}, []);

  useEffect(() => {
    if (bulkRoutes.enabled && bulkRoutes.routes) {
      Object.keys(bulkRoutes.routes).forEach(id => {
        map.setFeatureState(
          {
            source: sourceMapping[level],
            sourceLayer: `boundaries_postal_${level + 1}`,
            id
          },
          bulkRoutes.routes[id]
        );
      });
    }
  }, [bulkRoutes]);

  useEffect(() => {
    if (highlightSimilar.enabled) {
      return;
    }
    matchingIds.forEach(id => {
      map.setFeatureState(
        {
          source: sourceMapping[level],
          sourceLayer: `boundaries_postal_${level + 1}`,
          id
        },
        { matching: false }
      );
    });
    setMatchingIds([]);
  }, [highlightSimilar.enabled]);

  useEffect(() => {
    const loadPatterns = async () => {
      if (map) {
        const image = await symbolAsInlineImage({
          name: fillPattern(),
          width: "211.96",
          height: "211.96"
        });
        map.addImage("locked", image);
      }
    };

    if (!postcodeMapping) {
      return;
    }
    const array = Object.keys(postcodeMapping).map(key => [
      key,
      postcodeMapping[key].color
    ]);
    setLabelsColors([].concat(...array));
    loadPatterns();
  }, [postcodeMapping, map]);

  useEffect(() => {
    const newLevel = getZoomLevel(zoom);
    if (newLevel !== level) {
      setLevel(newLevel);
    }
  }, [zoom]);

  useEffect(() => {
    if (!onHover) {
      setHoverId(null);
      setHoverItem(null);
      setHoverPoint(null);
    }
  }, [onHover]);

  const mouseMove = e => {
    if (!map) {
      return;
    }
    const features = map.queryRenderedFeatures(e.point);
    let id;
    features.forEach(f => {
      if (f.id && Object.values(layerNames).includes(f.layer.id)) {
        id = f.id;
      }
    });

    if (onHover) {
      setHoverPoint(e.lngLat);
    }

    if (id === hoverId) {
      return;
    }

    map.setFeatureState(
      {
        source: sourceMapping[level],
        sourceLayer: `boundaries_postal_${level + 1}`,
        id: hoverId
      },
      { hover: false }
    );

    setHoverId(id);
    map.setFeatureState(
      {
        source: sourceMapping[level],
        sourceLayer: `boundaries_postal_${level + 1}`,
        id
      },
      { hover: true }
    );

    if (onHover) {
      const lookupTable = Object.values(
        getTable(level)[`pos${level + 1}`].data.all
      );
      const feature = lookupTable.filter(l => l.feature_id === id)[0];
      if (feature && feature.unit_code && level - 1 >= 0) {
        const matrixLine = findMatrixLine(
          {
            name: feature.unit_code.replace("  ", " "),
            level: level === 3 ? level : level - 1
          },
          matrixTemplate
        );
        setHoverItem(matrixLine.depot_code ? matrixLine : null);
      } else {
        setHoverItem(null);
      }
    }

    if (highlightSimilar.enabled) {
      const lookupTable = Object.values(
        getTable(level)[`pos${level + 1}`].data.all
      );
      const feature = lookupTable.filter(l => l.feature_id === id)[0];
      const matchingPostcodes = getSimilarFeatures({
        postcode: feature.unit_code.replace("  ", " "),
        level: level === 3 ? level : level - 1,
        matrixTemplate,
        type: highlightSimilar.type
      });
      const featureIds = lookupTable.filter(l =>
        matchingPostcodes.includes(l.unit_code.replace("  ", " "))
      );
      const ids = featureIds.map(f => f.feature_id);
      if (!isEqual(ids, matchingIds)) {
        matchingIds.forEach(id => {
          map.setFeatureState(
            {
              source: sourceMapping[level],
              sourceLayer: `boundaries_postal_${level + 1}`,
              id
            },
            { matching: false }
          );
        });
        ids.forEach(id => {
          map.setFeatureState(
            {
              source: sourceMapping[level],
              sourceLayer: `boundaries_postal_${level + 1}`,
              id
            },
            { matching: true }
          );
        });
        setMatchingIds(ids);
      }
    }
  };

  const mouseClick = e => {
    if (!map) {
      return;
    }
    const features = map.queryRenderedFeatures(e.point);
    let id;
    features.forEach(f => {
      if (f.id && Object.values(layerNames).includes(f.layer.id)) {
        id = f.id;
      }
    });
  };

  const common = {
    filter: ["==", ["get", "iso_3166_1"], "GB"],
    onMouseMove: e => {
      mouseMove(e);
    },
    onClick: e => mouseClick(e),
    type: "fill",
    paint: {
      "fill-color": bulkRoutes.enabled
        ? ["feature-state", "bulkRouteColor"]
        : [
            "case",
            // ["boolean", [">", ["length", ["feature-state", "bulkRouteColor"]], 0]],
            // ["feature-state", "bulkRouteColor"],
            ["boolean", ["feature-state", "matching"], false],
            highlightSimilar.colour,
            [
              "match",
              ["feature-state", "unit_code"],
              ...labelsColors,
              "#E5E5FF"
            ]
          ],
      "fill-opacity": [
        "case",
        [
          "case",
          ["==", depotFilter.enabled ? depotFilter.depot : "", ""],
          false,
          ["!=", ["feature-state", "depotCode"], depotFilter.depot],
          true,
          false
        ],
        0,
        ["boolean", ["feature-state", "hover"], false],
        0.6,
        0.2
      ],
      "fill-outline-color": "rgba(0, 0, 255, 0.6)"
    },
    before:
      matrixTemplateLayer.display &&
      sources[POSTCODE_LEVEL_MATRIX_ID] &&
      "template-layer"
  };

  const pattern = {
    filter: ["in", ["id"], ["literal", [13641293, 18818637]]],
    type: "fill",
    paint: {
      "fill-pattern": "locked",
      // "fill-opacity": [
      //   "case",
      //   [
      //     "case",
      //     ["==", depotFilter.enabled ? depotFilter.depot : "", ""],
      //     false,
      //     ["!=", ["feature-state", "depotCode"], depotFilter.depot],
      //     true,
      //     false
      //   ],
      //   0,
      //   ["boolean", ["feature-state", "hover"], false],
      //   0.6,
      //   0.2
      // ],
      "fill-outline-color": "rgba(0, 0, 255, 0.6)"
    }
  };

  const getSectorPlusOneMemoized = memoize(getSectorPlusOne);
  const sectorPlusOne = {
    filter: ["==", ["get", "iso_3166_1"], "GB"],
    type: "fill",
    paint: {
      "fill-color": ["case", ...getSectorPlusOneMemoized(), "#FFF"],
      "fill-opacity": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        0.6,
        0.2
      ],
      "fill-outline-color": "rgba(255, 255, 0, 0)"
    },
    onMouseMove: e => {
      const feature = map.queryRenderedFeatures(e.point, {
        layers: ["sector-plus-one-boundaries"]
      });
      const key = feature[0].state.unit_code.slice(0, -1);
      const features = sectorPlusOneTable[key].features;
      if (isEqual(features, hoverSectorPlusOne)) {
        return;
      }
      hoverSectorPlusOne.forEach(feature => {
        map.setFeatureState(
          {
            id: feature,
            source: POSTCODE_UNIT_BOUNDARIES_ID,
            sourceLayer: `boundaries_postal_4`
          },
          {
            hover: false
          }
        );
      });
      features.forEach(feature => {
        map.setFeatureState(
          {
            id: feature,
            source: POSTCODE_UNIT_BOUNDARIES_ID,
            sourceLayer: `boundaries_postal_4`
          },
          {
            hover: true
          }
        );
      });
      setHoverSectorPlusOne(features);
    }
  };

  const postcode = {
    filter: ["==", ["get", "iso_3166_1"], "GB"],
    type: "fill",
    paint: {
      "fill-color": [
        "case",
        ["==", ["feature-state", "selected"], true],
        "#FF0000",
        ...getSectorPlusOneMemoized(),
        "#FFF"
      ],
      "fill-opacity": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        0.6,
        0.2
      ],
      "fill-outline-color": "rgba(0, 0, 0, 0.8)"
    },
    onMouseMove: e => {
      const feature = map.queryRenderedFeatures(e.point, {
        layers: ["postcode-unit-boundaries"]
      });
      const id = feature[0].id;
      if (id === postcodeHighlight) {
        return;
      }
      if (postcodeHighlight) {
        map.setFeatureState(
          {
            id: postcodeHighlight,
            source: POSTCODE_UNIT_BOUNDARIES_ID,
            sourceLayer: `boundaries_postal_4`
          },
          {
            hover: false
          }
        );
      }
      map.setFeatureState(
        {
          id,
          source: POSTCODE_UNIT_BOUNDARIES_ID,
          sourceLayer: `boundaries_postal_4`
        },
        {
          hover: true
        }
      );
      setPostcodeHighlight(id);
    }
  };

  return (
    <>
      {sources[POSTCODE_DISTRICT_BOUNDARIES_ID] && (
        <Layer
          id={"postcode-district-boundaries"}
          sourceId={POSTCODE_DISTRICT_BOUNDARIES_ID}
          sourceLayer={`boundaries_postal_2`}
          maxZoom={10}
          {...common}
        />
      )}
      {sources[POSTCODE_SECTOR_BOUNDARIES_ID] && (
        <>
          <Layer
            id={"postcode-sector-boundaries"}
            sourceId={POSTCODE_SECTOR_BOUNDARIES_ID}
            sourceLayer={`boundaries_postal_3`}
            minZoom={10}
            maxZoom={13}
            before={"postcode-district-boundaries-2"}
            {...common}
          />
          <Layer
            id={"postcode-district-boundaries-2"}
            sourceId={POSTCODE_SECTOR_BOUNDARIES_ID}
            sourceLayer={`boundaries_postal_3`}
            minZoom={10}
            maxZoom={13}
            before={"postcode-sector-labels"}
            {...pattern}
          />
        </>
      )}
      {sources[POSTCODE_UNIT_BOUNDARIES_ID] && (
        <>
          <Layer
            id={"sector-plus-one-boundaries"}
            sourceId={POSTCODE_UNIT_BOUNDARIES_ID}
            sourceLayer={`boundaries_postal_4`}
            minZoom={13}
            maxZoom={15}
            {...sectorPlusOne}
          />
          <Layer
            id={"postcode-unit-boundaries"}
            sourceId={POSTCODE_UNIT_BOUNDARIES_ID}
            sourceLayer={`boundaries_postal_4`}
            minZoom={15}
            {...postcode}
          />
        </>
      )}
      {hoverItem && (
        <TemplatePopUp
          properties={hoverItem}
          coordinates={[hoverPoint.lng, hoverPoint.lat]}
        />
      )}
      {deliveryRoutes.enabled && <DeliveryRoutes map={map} />}
    </>
  );
};
