import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  postcodeAllocation: {},
  postcodeMapping: null
};

const postcodeAllocationReducer = createSlice({
  name: "postcodeAllocation",
  initialState,
  reducers: {
    setPostcodeAllocation: (state, action) => {
      state.postcodeAllocation = action.payload;
      const mapping = {};
      Object.values(action.payload).forEach(depot => {
        depot.postcodes.forEach(postcode => {
          mapping[postcode] = {
            color: depot.color,
            depotCode: depot.depotCode
          };
          mapping[postcode.substring(0, postcode.length - 2)] = {
            color: depot.color,
            depotCode: depot.depotCode
          };
        });
      });
      state.postcodeMapping = mapping;
    }
  }
});

export const { setPostcodeAllocation } = postcodeAllocationReducer.actions;
export default postcodeAllocationReducer.reducer;
